import { ls, url } from '@/utility';
import variables from '@/scss/abstract/_variablesExport.module.scss';

const updateStateFilters = (table, state) => {
  // if not updated from saved table data -> return state filters received from backend
  if (!table.updateFromSavedTableData) return table.stateFilters;

  // if updated from saved table data -> update saved stateFilters totalNum and return them
  const stateFilters = JSON.parse(JSON.stringify(state.savedTableData.stateFilters));
  stateFilters.choices.forEach((choice) => {
    // update totalNum from matched backend stateFilters choice
    const matchedChoice = table.stateFilters.choices.find((item) => item.value === choice.value);
    if (matchedChoice) choice.totalNum = matchedChoice.totalNum;
  });
  return stateFilters;
};

const setActiveStatusFilter = (statusFilters) => {
  if (statusFilters) {
    // if active props already exists
    // then state filters are from saved table data and we should return
    const activeExists = statusFilters.choices.filter((choice) => choice.active).length > 0;
    if (activeExists) return;

    const queryVal = url.getParameterByName(statusFilters.key);
    statusFilters.choices.forEach((choice, i) => {
      if (queryVal) {
        choice.active = choice.value === queryVal;
      } else {
        choice.active = i === 0;
      }
    });
  }
};

const updateBasicFilters = (state, filters) => {
  if (!state.basicFilters || !state.filters.length) {
    state.filters = filters;
  } else {
    state.filters.forEach((filter) => {
      const match = filters.find((item) => item.key === filter.key);
      if (match) filter.choices = match.choices;
    });
  }
};

const updateAdditionalFilters = (state, filters) => {
  state.tableAdditionalFilters = filters;
};

export default {
  setLoading(state, dataLoading) {
    state.loading = dataLoading;
  },
  setTableData(state, table) {
    state.tableData = table.tableData;
    state.bulkOptions = table.bulkOptions;
    state.bulkExtraOptions = table.bulkExtraOptionsV2;
    state.settings = table.settings;
    state.search = table.search;
    state.createNew = table.createNew;
    state.title = table.title;
    state.excelDownload = table.excelDownload;
    state.randomPeopleViewing = table.randomPeopleViewing;
    state.randomApplicantsViewing = table.randomApplicantsViewing;
    state.legend = table.legend;

    // get table pagination rowsPerPage from storage
    const { rowsPerPage } = ls.pagination.get();
    const pagination = rowsPerPage ? { ...table.pagination, rowsPerPage } : table.pagination;

    // restore data from saved table data or use received from backend
    const stateFilters = updateStateFilters(table, state);
    setActiveStatusFilter(stateFilters);
    state.stateFilters = stateFilters;
    state.basicFilters = table.updateFromSavedTableData ? state.savedTableData.basicFilters : {};
    updateBasicFilters(state, table.updateFromSavedTableData
      ? state.savedTableData.filters
      : table.filters);
    state.additionalFilters = table.updateFromSavedTableData
      ? state.savedTableData.additionalFilters : {};
    updateAdditionalFilters(state, table.updateFromSavedTableData
      ? state.savedTableData.tableAdditionalFilters : table.additionalFilters);
    state.sortKey = table.updateFromSavedTableData && state.savedTableData.sortKey ? JSON.parse(JSON.stringify(state.savedTableData.sortKey)) : '';
    state.searchVal = table.updateFromSavedTableData && state.savedTableData.searchVal
      ? JSON.parse(JSON.stringify(state.savedTableData.searchVal)) : '';
    state.pagination = table.updateFromSavedTableData
      && Object.keys(state.savedTableData.pagination).length
      ? JSON.parse(JSON.stringify(state.savedTableData.pagination)) : pagination;

    // Update LS pagination
    ls.pagination.set(state.pagination);
  },
  setTableDataOnly(state, table) {
    // get table pagination rowsPerPage from storage
    const { rowsPerPage } = ls.pagination.get();
    const pagination = rowsPerPage ? { ...table.pagination, rowsPerPage } : table.pagination;

    setActiveStatusFilter(table.stateFilters);
    state.tableData.body = table.tableData.body;
    state.bulkOptions = table.bulkOptions;
    state.pagination = pagination;
    updateBasicFilters(state, table.filters);
    updateAdditionalFilters(state, table.additionalFilters);
    state.stateFilters = table.stateFilters;
    state.title = table.title;
    state.randomPeopleViewing = table.randomPeopleViewing;
    state.randomApplicantsViewing = table.randomApplicantsViewing;
    // Update LS pagination
    ls.pagination.set(state.pagination);
  },
  refreshRow(state, options) {
    if (options.data.tableData.body.length === 0) return;

    const { id, tableId } = options;
    let index;

    // if default table
    if (!tableId) {
      index = state.tableData.body.findIndex((row) => row._id === id);
      if (index > -1) {
        // eslint-disable-next-line prefer-destructuring
        state.tableData.body[index] = options.data.tableData.body[0];
      }
    } else { // if additional table
      index = state.additionalTables[tableId].tableData.body.findIndex((row) => row._id === id);
      if (index > -1) {
        // eslint-disable-next-line prefer-destructuring
        state.additionalTables[tableId].tableData.body[index] = options.data.tableData.body[0];
      }
    }
  },
  setTableBasicFilters(state, settings) {
    if (settings.tableId) {
      state.additionalTables[settings.tableId].basicFilters = settings.filters;
    } else {
      state.basicFilters = settings.filters;
    }
  },
  setTableStatusFilter(state, settings) {
    if (settings.tableId) {
      state.additionalTables[settings.tableId].stateFilters.choices.forEach((filter) => {
        filter.active = filter.value === settings.filter?.value;
      });
    } else {
      state.stateFilters.choices.forEach((filter) => {
        filter.active = filter.value === settings.filter?.value;
      });
    }
  },
  setTableAdditionalFilters(state, settings) {
    if (settings.tableId) {
      state.additionalTables[settings.tableId].additionalFilters = settings.filters;
    } else {
      state.additionalFilters = settings.filters;
    }
  },
  setFavorite(state, data) {
    let row;

    if (state.tableData.body.length) {
      row = state.tableData.body.find((item) => item._id === data.id);
    } else if (state.additionalTables.applications) {
      row = state.additionalTables.applications.tableData.body.find((item) => item._id === data.id);
    } else if (state.additionalTables[data.tableId]) {
      row = state.additionalTables[data.tableId].tableData.body
        .find((item) => item._id === data.id);
    }

    if (row) {
      // Update favorite value
      row.evaluation_favorite.checked = data.checked;

      // Update favorite icon
      const icon = row.evaluation_favorite.icon.replace('far', 'fas');
      row.evaluation_favorite.icon = icon;

      // update favorite icon color
      const iconColor = data.checked ? variables.colorSuccess : variables.colorBackBasic;
      row.evaluation_favorite.iconColor = iconColor;
    }
  },
  updatePagination(state, settings) {
    if (settings.tableId) {
      settings.options.forEach((option) => {
        state.additionalTables[settings.tableId].pagination[option.key] = option.value;
      });
      // Update LS pagination
      ls.pagination.set(state.additionalTables[settings.tableId].pagination);
    } else {
      settings.options.forEach((option) => {
        state.pagination[option.key] = option.value;
      });
      // Update LS pagination
      ls.pagination.set(state.pagination);
    }
  },
  updateSearchVal(state, settings) {
    if (settings.tableId) {
      state.additionalTables[settings.tableId].searchVal = settings.searchVal;
    } else {
      state.searchVal = settings.searchVal;
    }
  },
  updateSortKey(state, settings) {
    if (settings.tableId) {
      state.additionalTables[settings.tableId].sortKey = settings.sortKey;
    } else {
      state.sortKey = settings.sortKey;
    }
  },
  deleteTableData(state, params) {
    // get table pagination rowsPerPage
    const { rowsPerPage } = ls.pagination.get();
    // clear pagination from local storage keeping rowsPerPage only
    ls.pagination.set({ rowsPerPage });

    // if table id empty -> delete default table data
    if (!params.tableId) {
      state.loading = false;
      state.tableData = {
        header: [],
        body: [],
      };
      state.bulkOptions = [];
      state.bulkExtraOptions = [];
      state.settings = {};
      state.filters = [];
      state.tableAdditionalFilters = {};
      // state.stateFilters = {};
      state.search = {};
      state.searchVal = '';
      state.createNew = {};
      state.excelDownload = {};
      state.randomPeopleViewing = {};
      state.randomApplicantsViewing = {};
      state.legend = [];

      // update pagination keeping rowsPerPage only
      state.pagination = { rowsPerPage };
    } else { // if table id exists -> delete additional table data
      state.additionalTables[params.tableId].loading = false;
      state.additionalTables[params.tableId].tableData = {
        header: [],
        body: [],
      };
      state.additionalTables[params.tableId].bulkOptions = [];
      state.additionalTables[params.tableId].bulkExtraOptions = [];
      state.additionalTables[params.tableId].settings = {};
      state.additionalTables[params.tableId].filters = [];
      state.additionalTables[params.tableId].tableAdditionalFilters = {};
      // state.additionalTables[params.tableId].stateFilters = {};
      state.additionalTables[params.tableId].search = {};
      state.additionalTables[params.tableId].searchVal = '';
      state.additionalTables[params.tableId].createNew = {};
      state.additionalTables[params.tableId].excelDownload = {};
      state.additionalTables[params.tableId].randomPeopleViewing = {};
      state.additionalTables[params.tableId].randomApplicantsViewing = {};
      state.additionalTables[params.tableId].legend = [];

      // update pagination keeping rowsPerPage only
      state.additionalTables[params.tableId].pagination = { rowsPerPage };
    }
  },
  setAdditionalTableData(state, settings) {
    // get table pagination rowsPerPage from storage
    const { rowsPerPage } = ls.pagination.get();
    const { table } = settings;
    if (settings.tableId) {
      if (settings.tableId in state.additionalTables) {
        state.additionalTables[settings.tableId].apiPoint = settings.additional.apiPoint;
        setActiveStatusFilter(table.stateFilters);
        state.additionalTables[settings.tableId].tableData = table.tableData;
        state.additionalTables[settings.tableId].pagination = rowsPerPage
          ? { ...table.pagination, rowsPerPage } : table.pagination;
        state.additionalTables[settings.tableId].bulkOptions = table.bulkOptions;
        state.additionalTables[settings.tableId].bulkExtraOptions = table.bulkExtraOptionsV2;
        state.additionalTables[settings.tableId].settings = table.settings;
        state.additionalTables[settings.tableId].filters = table.filters;
        state.additionalTables[settings.tableId].tableAdditionalFilters = table.additionalFilters;
        state.additionalTables[settings.tableId].stateFilters = table.stateFilters;
        state.additionalTables[settings.tableId].search = table.search;
        state.additionalTables[settings.tableId].createNew = table.createNew;
        state.additionalTables[settings.tableId].title = table.title;
        state.additionalTables[settings.tableId].excelDownload = table.excelDownload;
        state.additionalTables[settings.tableId].randomPeopleViewing = table.randomPeopleViewing;
        state.additionalTables[settings.tableId]
          .randomApplicantsViewing = table.randomApplicantsViewing;
        state.additionalTables[settings.tableId].legend = table.legend;
        // Update LS pagination
        ls.pagination.set(state.additionalTables[settings.tableId].pagination);
      }
    } else {
      setActiveStatusFilter(table.stateFilters);
      state.tableData = table.tableData;
      state.pagination = rowsPerPage ? { ...table.pagination, rowsPerPage } : table.pagination;
      state.bulkOptions = table.bulkOptions;
      state.bulkExtraOptions = table.bulkExtraOptionsV2;
      state.settings = table.settings;
      state.filters = table.filters;
      state.tableAdditionalFilters = table.additionalFilters;
      state.additionalFilters = {};
      state.stateFilters = table.stateFilters;
      state.search = table.search;
      state.createNew = table.createNew;
      state.title = table.title;
      state.excelDownload = table.excelDownload;
      state.randomPeopleViewing = table.randomPeopleViewing;
      state.randomApplicantsViewing = table.randomApplicantsViewing;
      state.legend = table.legend;
      // Update LS pagination
      ls.pagination.set(state.pagination);
    }
  },
  setAdditionalTableDataOnly(state, settings) {
    const { table } = settings;
    if (settings.tableId) {
      if (settings.tableId in state.additionalTables) {
        setActiveStatusFilter(table.stateFilters);
        state.additionalTables[settings.tableId].tableData.body = table.tableData.body;
        state.additionalTables[settings.tableId].tableData.header = table.tableData.header;
        state.additionalTables[settings.tableId].bulkOptions = table.bulkOptions;
        state.additionalTables[settings.tableId].pagination = table.pagination;
        updateBasicFilters(state.additionalTables[settings.tableId], table.filters);
        updateAdditionalFilters(state.additionalTables[settings.tableId], table.additionalFilters);
        state.additionalTables[settings.tableId].stateFilters = table.stateFilters;
        state.additionalTables[settings.tableId].randomPeopleViewing = table.randomPeopleViewing;
        state.additionalTables[settings.tableId]
          .randomApplicantsViewing = table.randomApplicantsViewing;
        // Update LS pagination
        ls.pagination.set(state.additionalTables[settings.tableId].pagination);
      }
    } else {
      setActiveStatusFilter(table.stateFilters);
      state.tableData.body = table.tableData.body;
      state.bulkOptions = table.bulkOptions;
      state.pagination = table.pagination;
      updateBasicFilters(state, table.filters);
      updateAdditionalFilters(state, table.additionalFilters);
      state.stateFilters = table.stateFilters;
      state.randomPeopleViewing = table.randomPeopleViewing;
      state.randomApplicantsViewing = table.randomApplicantsViewing;
      // Update LS pagination
      ls.pagination.set(state.pagination);
    }
  },
  prepareAdditionalTable(state, tableId) {
    const tableTemplate = JSON.parse(JSON.stringify(state.tableTemplate));
    state.additionalTables = {
      ...state.additionalTables,
      [tableId]: tableTemplate,
    };
  },
  setAdditionalTableLoading(state, settings) {
    if (settings.tableId) {
      state.additionalTables[settings.tableId].loading = settings.loading;
    } else {
      state.loading = settings.loading;
    }
  },
  // save table data before going to detail view
  saveTableData(state, params) {
    state.savedTableData.tableRoute = params.route;
    state.savedTableData.sortKey = JSON.parse(JSON.stringify(state.sortKey || ''));
    state.savedTableData.searchVal = JSON.parse(JSON.stringify(state.searchVal || ''));
    state.savedTableData.pagination = JSON.parse(JSON.stringify(state.pagination || {}));
    state.savedTableData.stateFilters = JSON.parse(JSON.stringify(state.stateFilters || {}));
    state.savedTableData.filters = JSON.parse(JSON.stringify(state.filters || []));
    state.savedTableData.basicFilters = JSON.parse(JSON.stringify(state.basicFilters || {}));
    state.savedTableData.tableAdditionalFilters = JSON.parse(JSON
      .stringify(state.tableAdditionalFilters || {}));
    state.savedTableData.additionalFilters = JSON.parse(JSON
      .stringify(state.additionalFilters || {}));
  },
  deleteSavedTableData(state) {
    state.savedTableData.tableRoute = null;
    state.savedTableData.sortKey = '';
    state.savedTableData.searchVal = '';
    state.savedTableData.pagination = {};
    state.savedTableData.stateFilters = {};
    state.savedTableData.filters = {};
    state.savedTableData.basicFilters = {};
    state.savedTableData.additionalFilters = {};
    state.savedTableData.tableAdditionalFilters = {};
    state.savedTableData.additionalFilters = {};
  },
};
