<template>
  <div
    class="mdt-checkbox-list"
    :class="{ 'has-error': $v.selectedValues.$error }">
    <div
      v-if="label"
      class="checkbox-label">
      {{ label }}
      <span
        v-if="!required && !hideOptional"
        class="label-optional">
        ({{ 'admin_marketing_optional' | translate }})
      </span>
      <i
        v-if="tooltip"
        v-tooltip="tooltip"
        class="far fa-info-circle info-icon" />
    </div>
    <MdtSearch
      v-if="showSearch"
      :value="searchVal"
      :placeholder="'general_search' | translate"
      size="size-32"
      @search="searchVal = $event" />
    <vue-scroll>
      <div
        class="checkbox-list-items"
        :class="{ grid, horizontal }"
        :style="{ 'max-height': itemsMaxHeight }">
        <MdtCheckbox
          v-for="(item, i) in filteredItems"
          :key="i"
          :checked="selectedValues.includes(item.value)"
          @change="onChange(item.value)">
          {{ item.displayName }}
        </MdtCheckbox>
      </div>
    </vue-scroll>
    <div
      v-if="limitItems && items.length > 6"
      class="show-all-button pointer"
      @click="showAllToggle = !showAllToggle">
      <span>
        {{ (showAllToggle ? 'general_close' : 'general_show_all') | translate }}
      </span>
      <i
        class="fas icon-caret"
        :class="[showAllToggle ? 'fa-caret-up' : 'fa-caret-down']" />
    </div>
    <div
      v-if="clientErrors.length"
      class="input-errors">
      <span class="client-errors">
        {{ clientErrors.join('\n') }}
      </span>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';

export default {
  name: 'MdtCheckboxList',
  mixins: [validationMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    itemsMaxHeight: {
      type: String,
      default: '',
    },
    grid: {
      type: Boolean,
      default: false,
    },
    limitItems: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
    hideOptional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValues: this.selected,
      searchVal: '',
      showAllToggle: false,
    };
  },
  computed: {
    filteredItems() {
      const items = this.items.filter(
        (item) => String(item.displayName).toLowerCase().includes(this.searchVal.toLowerCase()),
      );

      return !this.limitItems || this.showAllToggle ? items : items.slice(0, 6);
    },
    clientErrors() {
      const errors = [];
      if (this.$v.selectedValues.$dirty && !this.$v.selectedValues.required) {
        errors.push(this.$options.filters.translate('general_field_is_required'));
      }
      return errors;
    },
  },
  validations() {
    return {
      selectedValues: {
        required: () => (this.required ? this.selectedValues.length > 0 : true),
      },
    };
  },
  methods: {
    setTouched() {
      this.$v.selectedValues.$touch();
    },
    isValid() {
      this.setTouched();
      return !this.$v.selectedValues.$error;
    },
    onChange(value) {
      this.setTouched();

      const valueIndex = this.selectedValues.indexOf(value);

      if (valueIndex !== -1) {
        this.selectedValues.splice(valueIndex, 1);
      } else {
        this.selectedValues.push(value);
      }

      this.$emit('updateSelected', this.selectedValues);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-checkbox-list {
  &.has-error {
    color: $color-danger;

    ::v-deep {
      .mdt-checkbox {
        color: $color-danger;

        & > input {
          ~ .state label:before {
            border-color: rgba($color-danger, 0.1);
          }

          ~ .state label:after {
            background-color: rgba($color-danger, 0.2);
            border-color: rgba($color-danger, 0.1);
          }
        }
      }
    }
  }
}

.mdt-search {
  margin-bottom: 25px;
}

.checkbox-label {
  margin-bottom: 8px;
  color: $color-text-secondary;
  font-size: 14px;
  line-height: 14px;

  .info-icon {
    margin-left: 8px;
    color: $color-text-secondary;

    &:hover {
      color: $color-text-primary;
    }
  }
}

.checkbox-list-items {
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  .mdt-checkbox {
    margin-bottom: 20px;
  }

  &.horizontal {
    flex-direction: row;
    flex-wrap: wrap;

    .mdt-checkbox {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.grid {
    flex-direction: row;
    flex-wrap: wrap;
    white-space: normal;

    .mdt-checkbox {
      flex: 1 1 50%;
    }
  }
}

.show-all-button {
  font-weight: bold;

  span {
    border-bottom: 1px solid $color-text-primary;
  }

  i {
    margin-left: 8px;
    font-size: 16px;
  }
}

.input-errors {
  margin-top: -12px;
  font-size: 12px;
  font-weight: $font-weight-normal;
  white-space: pre-line;
}
</style>
