<template>
  <MdtModal
    ref="mdtMailPreview"
    :title="title"
    :is-data-changed="showDiscardConfirm()"
    :button-disabled="sendMailDisabled"
    width="1130px"
    height="700px"
    type="info"
    @cancel="$emit('close')"
    @close="$emit('close')">
    <slot name="modal" />
    <div
      v-if="sendMailDisabled"
      class="center-it">
      <MdtLoader />
      <div>{{ 'admin_mails_sending_msg' | translate }}</div>
    </div>
    <div
      v-else
      class="section">
      <div class="section-left">
        <MdtMailPreviewTemplateList
          v-if="showMailTemplateList && templates && templates.length > 0"
          :templates="templates"
          :mail-signature="mailSignature"
          @selectTemplate="onSelectMailTemplate" />
        <MdtMailPreviewEditor
          v-else
          ref="mailPreviewEditor"
          :mail-preview="mailPreview"
          :mail-template="mailTemplate"
          :loading="loading"
          :allow-attachment-upload="allowAttachmentUpload"
          :uploaded-files="uploadedFiles"
          :show-back-to-list="!!templates && !!templates.length"
          @selectTemplate="selectContact({ id: mailPreview.adultId }, true)"
          @saveMailTemplate="saveMailTemplate"
          @updateEditMode="isEditable = $event"
          @goBack="showMailTemplateList = true"
          @fileUpload="uploadedFiles.push(...$event)"
          @fileDelete="uploadedFiles.splice($event, 1)" />
      </div>
      <div class="section-right">
        <div
          class="section-right-content"
          @wheel.stop
          @touchmove.stop
          @scroll.stop>
          <vue-scroll v-if="contacts.length">
            <slot
              v-if="slotExists"
              name="section-right-label"
              class="section-right-label" />
            <div
              class="section-right-body"
              :class="{ 'slot-exists': slotExists }">
              <MdtInfoCard
                v-for="(contact, key) in contacts"
                :key="key"
                :ref="`contact-${key}`"
                :card-data="contact"
                :adult-id="mailPreview.adultId"
                :show-close="showInfoCardCloseBtn"
                :single-contact="singleContact()"
                :single-grouped-contact="singleGroupedContact()"
                :selectable="!showMailTemplateList && !isEditable"
                :class="{
                  selected: !showMailTemplateList && isGroupedContactSelected(contact),
                  'no-pointerevents': showMailTemplateList || isEditable,
                }"
                class="pointer"
                @select="selectContact"
                @remove="removeContact(key, $event)"
                @removeContact="removeContactItem(key, $event)" />
            </div>
          </vue-scroll>
        </div>
      </div>
    </div>
    <slot
      slot="button"
      name="button" />
    <div
      v-if="!isProjectTypeSale && showPdfDownloadBtn && !showMailTemplateList"
      slot="button"
      class="btn btn-light"
      :class="{
        'button-pdf': !changeState,
        'btn-bordered': changeState,
        'btn-disabled': isEditable || sendMailDisabled,
      }"
      @click="sendMails(true)">
      <i class="fas fa-arrow-to-bottom icon-space" />
      {{ 'general_download_as_pdf' | translate }}
    </div>
    <div
      slot="button"
      class="btn btn-primary"
      :class="{ 'btn-disabled': sendButtonDisabled }"
      @click="sendMails(undefined)">
      {{ (singleContact() ? 'admin_send_email' : 'admin_send_emails') | translate }}
    </div>
  </MdtModal>
</template>

<script>
import { helpers } from '@/utility';
import MdtInfoCard from '@/components/shared/MdtInfoCard.vue';
import MdtMailPreviewTemplateList from './MdtMailPreviewTemplateList.vue';
import MdtMailPreviewEditor from './MdtMailPreviewEditor.vue';

export default {
  name: 'MdtMailPreview',
  components: {
    MdtMailPreviewTemplateList,
    MdtMailPreviewEditor,
    MdtInfoCard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    mailPreview: {
      type: Object,
      required: true,
    },
    mailTemplate: {
      type: Object,
      required: true,
    },
    mailSignature: {
      type: Object,
      default: null,
    },
    templates: {
      type: Array,
      default: () => [],
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    changeState: {
      type: Boolean,
      default: false,
    },
    sendMailDisabled: {
      type: Boolean,
      default: false,
    },
    showPdfDownloadBtn: {
      type: Boolean,
      default: true,
    },
    showInfoCardCloseBtn: {
      type: Boolean,
      default: false,
    },
    allowAttachmentUpload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEditable: false,
      loading: false,
      uploadedFiles: [],
      showMailTemplateList: !this.mailTemplate
        || !this.mailTemplate.subject || !this.mailTemplate.body,
    };
  },
  computed: {
    slotExists() {
      return !!this.$slots['section-right-label']
        || !!this.$scopedSlots['section-right-label'];
    },
    sendButtonDisabled() {
      return this.showMailTemplateList || this.isEditable || this.sendMailDisabled || this.loading
        || !this.mailPreview?.subject || !this.mailPreview?.body;
    },
    isProjectTypeSale() {
      return this.$store.getters.isProjectType('sale');
    },
  },
  watch: {
    mailPreview() {
      this.loading = false;
    },
    showMailTemplateList(value) {
      if (value) {
        /* eslint-disable vue/no-mutating-props */
        this.mailTemplate.subject = '';
        this.mailTemplate.body = '';
        this.mailTemplate.id = '';
        /* eslint-enable vue/no-mutating-props */
        this.uploadedFiles = [];
        this.isEditable = false;
      }
    },
  },
  methods: {
    onSelectMailTemplate(mailTemplate) {
      this.loading = true;
      /* eslint-disable vue/no-mutating-props */
      this.mailTemplate.subject = mailTemplate?.subject || '';
      this.mailTemplate.body = mailTemplate?.body || '';
      this.mailTemplate.id = mailTemplate?.id || '';
      this.mailTemplate.new = mailTemplate === null;
      /* eslint-enable vue/no-mutating-props */
      this.selectContact({ id: this.mailPreview.adultId }, true);
      this.showMailTemplateList = false;
    },
    singleContact() {
      return this.contacts.length === 1;
    },
    singleGroupedContact() {
      return this.singleContact() && this.contacts[0]?.contacts?.length === 1;
    },
    isValid() {
      if (this.showMailTemplateList || this.isEditable || this.sendMailDisabled) return false;
      const { mailPreviewEditor: mailPreviewEditorRef } = this.$refs;
      if (mailPreviewEditorRef && !mailPreviewEditorRef.isValid()) return false;
      return true;
    },
    showDiscardConfirm() {
      if (this.showMailTemplateList) return false;
      const { mailPreviewEditor: mailPreviewEditorRef } = this.$refs;
      if (mailPreviewEditorRef && mailPreviewEditorRef.showDiscardConfirm) return true;
      return false;
    },
    saveMailTemplate(mailTemplate) {
      this.isEditable = false;
      /* eslint-disable vue/no-mutating-props */
      this.mailTemplate.subject = mailTemplate.subject;
      this.mailTemplate.body = mailTemplate.body;
      this.mailTemplate.id = mailTemplate.id;
      /* eslint-enable */

      // Skip selectContact if adult/contact does not exist
      if (this.mailPreview.adultId) {
        // Select contact to refresh mailPreview
        const contactId = this.mailPreview.adultId;
        this.selectContact({ id: contactId }, true);
      } else {
        const args = {
          mailTemplate: this.mailTemplate,
          cancelLoading: this.cancelLoading,
        };
        this.$emit('getMailPreview', args);
      }
    },
    selectContact(contact, templateSelection) {
      this.$nextTick(() => {
        if (this.isEditable && !templateSelection) return;
        // Prevent same card selection if same mailPreview is shown
        if (!templateSelection) {
          if (this.contacts.length && this.isGroupedContactSelected(contact)) return;
        }

        contact = contact.id === undefined ? this.contacts[0]?.contacts[0] : contact;

        const mailTemplate = {
          adultId: contact.id,
          subject: this.mailTemplate.subject,
          body: this.mailTemplate.body,
          templateId: helpers.valueOrUndefined(this.mailTemplate.id),
          apartmentId: helpers.valueOrUndefined(this.$store?.getters['apartments/apartmentId']),
        };
        this.loading = true;
        this.$emit('getMailPreview', { mailTemplate, cancelLoading: this.cancelLoading });
      });
    },
    removeContact(i, contact) {
      if (this.isEditable) return;
      if (this.singleContact()) return;

      this.removeGroupedContact(i, contact);
    },
    removeGroupedContact(i, cardData) {
      this.contacts.splice(i, 1); // eslint-disable-line vue/no-mutating-props
      this.$parent.$forceUpdate();
      // // Select first contact if selected contact is removed
      if (this.isGroupedContactSelected(cardData)) {
        this.selectContact(this.contacts[0], true);
      }
    },
    removeContactItem(i, contact) {
      if (this.isEditable) return;
      const index = this.contacts[i].contacts.findIndex((item) => item.id === contact.id);
      if (index > -1) {
        // eslint-disable-next-line vue/no-mutating-props
        this.contacts[i].contacts.splice(index, 1);
        this.$parent.$forceUpdate();
        // // Select first contact in group if selected contact is removed
        if (this.mailPreview.adultId === contact.id) {
          this.selectContact(this.contacts[i], true);
        }
      }
    },
    sendMails(pdfDownload) {
      if (!this.isValid()) return;
      const targets = this.getContactIds();
      const settings = {
        params: {
          targets,
          subject: this.mailTemplate.subject,
          body: this.mailTemplate.body,
          templateId: helpers.valueOrUndefined(this.mailTemplate.id),
          pdf: pdfDownload,
          attachments: this.uploadedFiles,
          apartmentId: helpers.valueOrUndefined(this.$store?.getters['apartments/apartmentId']),
        },
      };
      this.$emit('sendMails', settings);
    },
    getContactIds() {
      let contactIds = [];
      this.contacts.forEach((item) => {
        contactIds = [...contactIds, ...item.contacts.map((contact) => contact.id)];
      });
      return contactIds;
    },
    isGroupedContactSelected(groupedContact) {
      let contactIds = [];
      if ('contacts' in groupedContact) {
        groupedContact.contacts.forEach((contact) => {
          contactIds.push(contact.id);
        });
      } else contactIds = [groupedContact.id];
      return contactIds.includes(this.mailPreview.adultId);
    },
    cancelLoading() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  display: flex;
  flex-direction: row;

  .section-left,
  .section-right {
    display: flex;
  }

  .section-left {
    width: 600px;
    margin-right: 20px;
  }

  .section-right {
    width: 450px;
    border-left: 1px solid $border-color;
  }

  .mdt-mail-preview-editor {
    width: 100%;
  }
}

.section-right-content {
  display: flex;
  flex-direction: column;
  width: 100%;

  ::v-deep .section-right-label {
    height: 32px;
    padding: 0 20px;
    font-weight: $font-weight-bold;
  }

  .section-right-body {
    height: calc(700px - #{$modal-header-height} - #{$modal-header-height} - 32px);
    padding: 2px 0 2px 20px;

    &.slot-exists {
      height: calc(700px - #{$modal-header-height} - #{$modal-header-height} - 62px);
      padding-top: 20px;
    }

    .mdt-info-card:not(:last-child) {
      margin-bottom: 30px;
    }

    .mdt-brief-info-label:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.button-pdf {
  position: absolute;
  left: 20px;
}
</style>
