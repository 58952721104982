import './moment';
import Vue from 'vue';
import VueContentPlaceholders from 'vue-content-placeholders';
import vuescroll from './vuescroll';
import packeryPlugin from './packery';
import draggabillyPlugin from './draggabilly';
import vtooltip from './vtooltip';
import lightbox from './lightbox';
import draggable from './draggable';
import slick from './slick';
import freshdeskHelpWidget from './freshdeskHelpWidget';
import headwayWidget from './headwayWidget';
import './elements';
import './custom';
import stzhComponents from '@/plugins/stzhComponents';

Vue.use(vuescroll.plugin, vuescroll.options);
Vue.use(packeryPlugin);
Vue.use(draggabillyPlugin);
Vue.use(vtooltip);
Vue.use(lightbox);
Vue.use(draggable);
Vue.use(VueContentPlaceholders);
Vue.use(slick);
Vue.use(freshdeskHelpWidget);
Vue.use(headwayWidget);
Vue.use(stzhComponents);

export default {};
