<template>
  <div
    class="table-main"
    :class="[tableId, {
      'flex-center': !headersVisible,
      'main-content': useMainContentWrapper,
      'search-and-bulk-inline': searchVisible && !bulkOptionsVisible
        && !mainFiltersVisible && !additionalFiltersVisible,
    }]">
    <div v-if="!showPlaceholders">
      <TableMainTitle
        v-if="mainTitle"
        :title="mainTitle" />
      <MdtTableStatusFilters
        v-if="table.stateFilters && Object.keys(table.stateFilters).length"
        :status-filters="table.stateFilters"
        @applyStatusFilter="applyStatusFilter" />
      <TableRandomGeneratorActivated
        v-if="table.randomPeopleViewing && table.randomPeopleViewing.activated"
        :random-people-viewing="table.randomPeopleViewing" />
      <TableRandomApplicantsGeneratorActivated
        v-if="table.randomApplicantsViewing && table.randomApplicantsViewing.messages.length"
        :random-applicants-viewing="table.randomApplicantsViewing" />
      <MdtTableFilters
        :search="table.search"
        :filters="table.filters"
        :additional-filters="table.tableAdditionalFilters"
        :table-id="tableId"
        @search="onSearch"
        @applyFilters="applyFilters"
        @applyAdditionalFilters="applyAdditionalFilters" />
      <MdtTable
        ref="mdtTable"
        :table-id="tableId"
        :table="table"
        :sticky-headers="stickyHeaders"
        :editable="editable"
        :max-selected="maxSelected"
        :display-scroll-arrows="displayScrollArrows"
        :scroll-arrows-position="scrollArrowsPosition"
        :scroll-arrows-sticky-position="scrollArrowsStickyPosition"
        @sortColumn="onSortColumn"
        @updatePagination="updatePagination"
        @setFavorite="$emit('setFavorite', $event)"
        @openSidepanel="$emit('openSidepanel', $event)"
        @saveReorder="onSaveReorder"
        @bulkOptionSelect="$emit('bulkOptionSelect', $event)"
        @bulkExtraOptionSelect="$emit('bulkExtraOptionSelect', $event)"
        @selectDetailOption="$emit('selectDetailOption', $event)"
        @toggleButton="$emit('toggleButton', $event)"
        @selectedRows="$emit('selectedRows', $event)"
        @reservation="$emit('reservation', $event)"
        @tableSaved="$emit('tableSaved')"
        @createNew="$emit('createNew', $event)"
        @tableTdButtonSelect="$emit('tableTdButtonSelect', $event)"
        @openRandomPeopleViewing="$emit('openRandomPeopleViewing', table.tableData.body.length)"
        @openRandomApplicantsViewing="$emit('openRandomApplicantsViewing', table.tableData.body.length)" />
    </div>
    <ul
      v-else
      class="placeholders-list">
      <content-placeholders
        v-for="(index) in 7"
        :key="index"
        class="placeholders-list-item">
        <content-placeholders-heading />
        <content-placeholders-text :lines="4" />
      </content-placeholders>
    </ul>
  </div>
</template>

<script>
import MdtTable from '@/components/shared/table/MdtTable.vue';
import MdtTableFilters from '@/components/shared/table/MdtTableFilters.vue';
import MdtTableStatusFilters from '@/components/shared/table/MdtTableStatusFilters.vue';
import TableRandomGeneratorActivated from '@/components/viewing-appointments/TableRandomGeneratorActivated.vue';
import TableRandomApplicantsGeneratorActivated from '@/components/viewing-appointments/TableRandomApplicantsGeneratorActivated.vue';
import TableMainTitle from './TableMainTitle.vue';

export default {
  name: 'TableMain',
  components: {
    MdtTable,
    MdtTableFilters,
    MdtTableStatusFilters,
    TableMainTitle,
    TableRandomGeneratorActivated,
    TableRandomApplicantsGeneratorActivated,
  },
  props: {
    tableId: {
      type: String,
      default: '',
    },
    dataRequest: {
      type: Object,
      default: () => {},
    },
    mainTitle: {
      type: String,
      default: '',
    },
    useMainContentWrapper: {
      type: Boolean,
      default: true,
    },
    stickyHeaders: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    maxSelected: {
      type: Number,
      default: undefined,
    },
    displayScrollArrows: {
      type: Boolean,
      default: true,
    },
    scrollArrowsPosition: {
      type: Object,
      default: () => ({
        top: '67px',
        right: 0,
      }),
    },
    scrollArrowsStickyPosition: {
      type: Object,
      default: () => ({
        top: '77px',
        right: '80px',
      }),
    },
  },
  computed: {
    table: {
      get() {
        if (this.tableId) return this.$store.state.table.additionalTables[this.tableId];
        return this.$store.state.table;
      },
      set(table) {
        if (this.tableId) this.$store.state.table.additionalTables[this.tableId] = table;
        else this.$store.state.table = table;
      },
    },
    showPlaceholders() {
      const header = this.table?.tableData?.header || [];
      return !header.length;
    },
    headersVisible() {
      return !!(this.table?.tableData?.header?.length);
    },
    bulkOptionsVisible() {
      return !!(this.table?.bulkOptions?.length);
    },
    mainFiltersVisible() {
      return !!(this.table?.filters?.length);
    },
    additionalFiltersVisible() {
      return !!(this.table.tableAdditionalFilters
        && Object.keys(this.table.tableAdditionalFilters).length);
    },
    searchVisible() {
      return !!(this.table.search && this.table.search.enabled);
    },
  },
  created() {
    if (this.isDataRequestSent()) this.$store.dispatch('table/prepareAdditionalTable', this.tableId);
  },
  mounted() {
    if (this.isDataRequestSent()) this.getTableData();
  },
  methods: {
    isDataRequestSent() {
      return this.dataRequest && Object.keys(this.dataRequest).length > 0;
    },
    getTableData(dataOnly) {
      this.$emit('cancelEdit');
      const settings = {
        tableId: this.tableId,
        dataOnly,
        params: { ...this.dataRequest.params },
        additional: { ...this.dataRequest.additional },
      };
      this.$store.dispatch(this.dataRequest.path, settings).then(() => {
        this.$nextTick(() => {
          this.$emit('additionalTableDataLoaded');
          this.setActiveStatusFilterForAdditionalTable();
        });
      }).catch((err) => {
        this.showResponseError(err);
        this.$store.dispatch('table/deleteTableData', { tableId: this.tableId });
      });
    },
    checkIsAdditionalTable() {
      switch (this.tableId) {
        case 'investorsWithOffer':
        case 'matchingInvestors':
        case 'interested':
        case 'applications':
          return true;
        default:
          return false;
      }
    },
    setActiveStatusFilterForAdditionalTable() {
      /*
          * This request is triggered only for additional tabels
          * Additional tables are tables in detail view of some main table row
          * Initially when additional table is loaded
          * set active status filter to 1st tab of additional table
          * payload format for this action is
          * {
              "tableId": "applications",
              "filters": {
                  "facets-progress_state": "all"
              }
            }
      * */
      if (this.checkIsAdditionalTable()) {
        const foundChoice = this.table.tableAdditionalFilters.choices
          .find((choice) => choice.selected);
        const payload = {
          tableId: this.tableId,
          filters: {
            [this.table.tableAdditionalFilters.key]: foundChoice.value,
          },
        };
        this.$store.dispatch('table/setTableAdditionalFiltersOnly', payload);
      }
    },
    onSortColumn(key) {
      this.$emit('cancelEdit');
      if (this.isDataRequestSent()) {
        const settings = {
          tableId: this.tableId,
          sortKey: key,
        };
        this.$store.dispatch('table/updateSortKeyOnly', settings);
        this.getTableData(true);
      } else this.$store.dispatch('table/sortTableData', key);
    },
    updatePagination(options) {
      this.$emit('cancelEdit');
      if (this.isDataRequestSent()) {
        const settings = {
          tableId: this.tableId,
          options,
        };
        this.$store.dispatch('table/updatePaginationOnly', settings);
        this.getTableData(true);
      } else this.$store.dispatch('table/updatePagination', options);
    },
    onSaveReorder(params) {
      this.$emit('cancelEdit');
      if (this.isDataRequestSent()) {
        this.$store.dispatch('saveReorderSettings', params).then(() => {
          this.getTableData();
        }).catch(this.showResponseError);
      } else {
        this.$store.dispatch('saveReorderSettings', params).then(() => {
          this.$store.dispatch('table/getTableData');
        }).catch(this.showResponseError);
      }
    },
    onSearch(searchVal) {
      this.$emit('cancelEdit');
      if (this.isDataRequestSent()) {
        const settings = {
          tableId: this.tableId,
          searchVal,
        };
        this.$store.dispatch('table/updateSearchValOnly', settings);
        this.getTableData(true);
      } else this.$store.dispatch('table/search', searchVal);
    },
    applyFilters(filters) {
      this.$emit('cancelEdit');
      if (this.isDataRequestSent()) {
        const settings = {
          tableId: this.tableId,
          filters,
        };
        this.$store.dispatch('table/setTableBasicFiltersOnly', settings);
        this.getTableData(true);
      } else this.$store.dispatch('table/setTableBasicFilters', filters);
    },
    applyStatusFilter(filter) {
      this.$emit('cancelEdit');
      if (this.isDataRequestSent()) {
        const settings = {
          tableId: this.tableId,
          filter,
        };
        this.$store.dispatch('table/setTableStatusFilterOnly', settings);
        this.getTableData(true);
      } else this.$store.dispatch('table/setTableStatusFilter', filter);
    },
    applyAdditionalFilters(filters) {
      this.$emit('cancelEdit');
      if (this.isDataRequestSent()) {
        const settings = {
          tableId: this.tableId,
          filters,
        };
        this.$store.dispatch('table/setTableAdditionalFiltersOnly', settings);
        this.getTableData(true);
      } else this.$store.dispatch('table/setTableAdditionalFilters', filters);
    },
    getSelectedRows() {
      const { mdtTable: mdtTableRef } = this.$refs;
      return mdtTableRef ? mdtTableRef.selectedRows : [];
    },
    updateSelectedRows(selectedRows, preventUpdateSelectedRows) {
      const { mdtTable: mdtTableRef } = this.$refs;
      if (mdtTableRef) mdtTableRef.updateSelectedRows(selectedRows, preventUpdateSelectedRows);
    },
    saveTableTd() {
      const tableRef = this.$refs.mdtTable;
      if (tableRef) tableRef.saveTableTd();
    },
  },
};
</script>

<style lang="scss" scoped>
.table-main {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 40px;

  &:last-child {
    padding-bottom: 0;
  }

  &.search-and-bulk-inline {
    ::v-deep {
      .mdt-table {
        padding-top: 53px;
      }

      .bulk-options {
        margin-top: -106px;
        margin-left: 257px;
      }

      .main-table-filters {
        justify-content: flex-start;
      }
    }
  }

  &.main-content {
    ::v-deep .pagination {
      padding-bottom: 20px;
    }
  }

  ::v-deep .table-wrapper {
    min-height: 70px;
  }

  .placeholders-list {
    width: 100%;
    overflow: hidden;

    .placeholders-list-item {
      margin: 20px 0;
      padding: 0 20px 10px 20px;
      border-bottom: 1px solid $border-color;
    }
  }
}
</style>
