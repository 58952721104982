import { render, staticRenderFns } from "./MdtDatepicker.vue?vue&type=template&id=0166e8ea&scoped=true"
import script from "./MdtDatepicker.vue?vue&type=script&lang=js"
export * from "./MdtDatepicker.vue?vue&type=script&lang=js"
import style0 from "./MdtDatepicker.vue?vue&type=style&index=0&id=0166e8ea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0166e8ea",
  null
  
)

export default component.exports