<template>
  <div
    v-click-outside="closeDropdown"
    class="mdt-timepicker">
    <div class="timepicker-input">
      <MdtInput
        ref="inputEl"
        v-model="inputValue"
        :label="label"
        :placeholder="useSeconds ? 'HH:mm:ss' : 'HH:mm'"
        :required="required"
        :disabled="disabled"
        :readonly="readonly"
        :type="useSeconds ? 'timeWithSeconds' : 'time'"
        :errors="errors"
        class="input"
        @input="setInput"
        @focus="onToggleDropdown">
        <i
          slot="icon"
          class="fas fa-clock icon no-pointerevents"
          :class="{ selected: selected.hours !== '-' }" />
      </MdtInput>
    </div>
    <div
      v-if="isDropdownOpen"
      ref="scroll"
      class="timepicker-picker">
      <div class="wrapper">
        <div class="timepicker-hours">
          <i
            class="fa fa-angle-up pointer icon-angle"
            @click="onUp('hours')" />
          <div class="timepicker-selected-hours timepicker-value">
            {{ selected.hours }}
          </div>
          <i
            class="fa fa-angle-down pointer icon-angle"
            @click="onDown('hours')" />
        </div>
        <span>:</span>
        <div class="timepicker-minutes">
          <i
            class="fa fa-angle-up pointer icon-angle"
            @click="onUp('minutes')" />
          <div class="timepicker-selected-minutes timepicker-value">
            {{ selected.minutes }}
          </div>
          <i
            class="fa fa-angle-down pointer icon-angle"
            @click="onDown('minutes')" />
        </div>
        <span v-if="useSeconds">:</span>
        <div
          v-if="useSeconds"
          class="timepicker-seconds">
          <i
            class="fa fa-angle-up pointer icon-angle"
            @click="onUp('seconds')" />
          <div class="timepicker-selected-seconds timepicker-value">
            {{ selected.seconds }}
          </div>
          <i
            class="fa fa-angle-down pointer icon-angle"
            @click="onDown('seconds')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixinToggleDropdown } from '@/mixins';

export default {
  name: 'MdtTimepicker',
  mixins: [mixinToggleDropdown],
  props: {
    value: {
      type: [String, Date],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    useSeconds: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: {
        hours: '-',
        minutes: '-',
        seconds: '-',
      },
      inputValue: '',
    };
  },
  watch: {
    value(value) {
      this.inputValue = value;
    },
    isDropdownOpen(value) {
      if (value) {
        this.$nextTick(() => {
          this.scrollTheView();
        });
      }
    },
  },
  mounted() {
    this.setInput(this.value, 'init');
  },
  methods: {
    isValid() {
      if (this.required) return this.$refs.inputEl.isValid() && this.isTimeValid(this.inputValue);
      return this.$refs.inputEl.isValid();
    },
    isTimeValid(time) {
      const regex = this.useSeconds
        ? /^([0-9]|0[0-9]|1[0-9]|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/
        : /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      if (!regex.test(time)) return false;
      return true;
    },
    setInput(value, init) {
      if (!this.isTimeValid(value)) {
        this.onInvalidInput(value, init);
        return;
      }
      const timeStr = value.split(':');
      const mask = {
        1: 'HH',
        2: 'HH:mm',
        3: 'HH:mm:ss',
      }[timeStr.length];
      const time = moment(value, mask);
      const date = moment();
      date.set({
        hour: time.get('hour'),
        minute: time.get('minute'),
        second: time.get('second'),
      });
      this.setTime(date, init);
    },
    onInvalidInput(time, init) {
      this.selected.hours = '-';
      this.selected.minutes = '-';
      this.selected.seconds = '-';
      this.inputValue = time;
      this.$emit('onSelectedInput', this.inputValue);

      if (!init) {
        // emit mdtDataChanged event so changes could be detected
        this.$emit('mdtDataChanged');
      }
    },
    setTime(date, init) {
      this.selected.hours = date ? this.formatZeroPadding(date.hours()) : '-';
      this.selected.minutes = date ? this.formatZeroPadding(date.minutes()) : '-';
      this.selected.seconds = date ? this.formatZeroPadding(date.seconds()) : '-';
      this.setInputValue();
      this.$emit('onSelectedInput', this.inputValue);

      if (!init) {
        // emit mdtDataChanged event so changes could be detected
        this.$emit('mdtDataChanged');
      }

      this.closeDropdown();
    },
    setInputValue() {
      const { selected } = this;
      if (this.useSeconds && selected.hours !== '-' && selected.minutes !== '-' && selected.seconds !== '-') {
        this.inputValue = `${selected.hours}:${selected.minutes}:${selected.seconds}`;
        return;
      }
      if (!this.useSeconds && selected.hours !== '-' && selected.minutes !== '-') {
        this.inputValue = `${selected.hours}:${selected.minutes}`;
        return;
      }
      this.inputValue = '';
    },
    formatZeroPadding(number) {
      return (`0${number}`).slice(-2);
    },
    onUp(type) {
      if (this.selected[type] === '-') this.selected[type] = '00';
      else {
        let value = parseInt(this.selected[type], 10);
        const max = type === 'hours' ? 23 : 59;
        if (value < max) this.selected[type] = this.formatZeroPadding(++value);
      }
      this.setInputValue();
      if (this.inputValue) {
        this.$emit('onSelectedInput', this.inputValue);

        // emit mdtDataChanged event so changes could be detected
        this.$emit('mdtDataChanged');
      }
    },
    onDown(type) {
      if (this.selected[type] === '-') this.selected[type] = type === 'hours' ? '23' : 59;
      else {
        let value = parseInt(this.selected[type], 10);
        if (value > 0) this.selected[type] = this.formatZeroPadding(--value);
      }
      this.setInputValue();
      if (this.inputValue) {
        this.$emit('onSelectedInput', this.inputValue);

        // emit mdtDataChanged event so changes could be detected
        this.$emit('mdtDataChanged');
      }
    },
    scrollTheView() {
      const { scroll } = this.$refs;
      if (scroll) {
        // scroll the view if neccessary to show whole component
        scroll.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    },
    onToggleDropdown() {
      if (this.disabled || this.readonly) return;
      this.toggleDropdown();
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-timepicker {
  position: relative;
  background-color: $color-back-primary;
  font-size: 1rem;

  ::v-deep .input-wrapper .icon.selected {
    color: $color-text-secondary;
  }
}

::v-deep .timepicker-input .input .input-wrapper input {
  cursor: pointer;
}

.timepicker-picker {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  padding: 5px 0;
  box-shadow: 0 3px 6px #00000040;
  border: 1px solid $border-color;
  border-radius: 4px;
  background-color: $color-back-primary;
  z-index: 101;

  .wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: nowrap;

    .timepicker-hours,
    .timepicker-minutes,
    .timepicker-seconds {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .timepicker-value {
      padding: 5px 0;
    }

    .icon-angle {
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;

      &:hover {
        background-color: $color-back-hover;
        border-radius: 50%;
      }
    }
  }
}
</style>
