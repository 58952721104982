import MdtButtonSelect from '@/components/shared/buttons/MdtButtonSelect.vue';
import MdtEditButton from '@/components/shared/buttons/MdtEditButton.vue';
import MdtStatusButtons from '@/components/shared/buttons/MdtStatusButtons.vue';
import MdtButton from '@/components/shared/buttons/MdtButton.vue';
import MdtSectionToggle from '@/components/shared/buttons/MdtSectionToggle.vue';

export default {
  install(Vue) {
    Vue.component('MdtButtonSelect', MdtButtonSelect);
    Vue.component('MdtEditButton', MdtEditButton);
    Vue.component('MdtStatusButtons', MdtStatusButtons);
    Vue.component('MdtButton', MdtButton);
    Vue.component('MdtSectionToggle', MdtSectionToggle);
  },
};
