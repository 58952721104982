<template>
  <div
    class="mdt-input"
    :class="[{
      'has-error': inputHasError,
      disabled,
      readonly,
    }, `input-${size}`]">
    <div
      v-if="label"
      class="input-label">
      <div>
        <span>{{ label }}</span>
        <span
          v-if="!required && !hideOptional"
          class="input-label-optional">
          ({{ 'admin_marketing_optional' | translate }})
        </span>
        <i
          v-if="required"
          class="fa-asterisk field-required" />
      </div>
      <i
        v-if="tooltip"
        v-tooltip="tooltip"
        class="far fa-info-circle info-icon" />
    </div>
    <div
      class="input-wrapper"
      :class="{ 'has-icon': !!$slots['icon'] }">
      <input
        ref="input"
        v-overflow-tooltip
        v-tooltip="{
          content: type !== 'password' ? value : '',
          trigger: 'manual',
        }"
        title=""
        :value="value"
        :type="['date', 'time', 'timeWithSeconds'].includes(type) ? 'text' : type"
        :required="required"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :step="step"
        autocomplete="new-password"
        :size="inputSize !== null ? inputSize : 20"
        class="text-cut"
        @input="onInput"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @keyup.enter="$emit('keyupEnter', value)"
        @click="$emit('clickInput')">
      <slot name="icon" />
    </div>
    <div
      v-if="clientErrors.length || serverErrors.length"
      class="input-errors">
      <span class="client-errors">
        {{ clientErrors.join('\n') }}
        {{ clientErrors.length && serverErrors.length ? '\n' : '' }}
      </span>
      <span class="server-errors">
        {{ serverErrors.join('\n') }}
      </span>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  email,
  integer,
  decimal,
  minValue,
  maxValue,
  minLength,
  maxLength,
  helpers,
  sameAs,
} from 'vuelidate/lib/validators';

export default {
  name: 'MdtInput',
  mixins: [validationMixin],
  props: {
    value: {
      required: true,
      validator: (value) => {
        const isString = typeof value === 'string';
        const isNumber = typeof value === 'number';
        const isNull = value === null;
        return isString || isNumber || isNull;
      },
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) => {
        const match = ['text', 'number', 'email', 'password', 'date', 'time', 'timeWithSeconds'];
        return match.includes(value);
      },
    },
    integers: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: Number.MIN_SAFE_INTEGER,
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
    step: {
      type: Number,
      default: 1,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    tooltip: {
      type: String,
      default: '',
    },
    minLength: {
      type: Number,
      default: 0,
    },
    maxLength: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
    pattern: {
      type: RegExp,
      default: null,
    },
    size: {
      type: String,
      default: 'size-40',
      validator: (value) => {
        const match = ['size-40', 'size-32'];
        return match.includes(value);
      },
    },
    inputSize: {
      type: Number,
      default: null,
    },
    hideOptional: {
      type: Boolean,
      default: false,
    },
    emailValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputValue: this.value,
      serverErrors: this.errors,
      msg: {
        minValidation: this.$options.filters.translate('admin_field_greater_or_equal_then_minimum')
          .replace('[p]', this.label || this.$options.filters.translate('general_number'))
          .replace('[m]', this.min),
        maxValidation: this.$options.filters.translate('admin_field_smaller_or_equal_then_maximum')
          .replace('[p]', this.label || this.$options.filters.translate('general_number'))
          .replace('[m]', this.max),
        minLengthValidation: this.$options.filters.translate('general_min_length_error')
          .replace('[p]', this.label || this.$options.filters.translate('general_field'))
          .replace('[d]', this.minLength),
        maxLengthValidation: this.$options.filters.translate('admin_max_length_error_msg')
          .replace('[p]', this.label || this.$options.filters.translate('general_field'))
          .replace('[d]', this.maxLength),
        patternValidationDefault: this.$options.filters.translate('admin_enter_valid_field')
          .replace('[d]', this.label || this.$options.filters.translate('general_field')),
        emailMismatched: this.$options.filters.translate('general_email_mismatch_error_msg'),
      },
    };
  },
  computed: {
    clientErrors() {
      const errors = [];
      if (this.$v.inputValue.$dirty) {
        if (!this.$v.inputValue.required) {
          errors.push(this.$options.filters.translate('general_field_is_required'));
        }
        if (!this.$v.inputValue.minLength) {
          errors.push(this.msg.minLengthValidation);
        }
        if (!this.$v.inputValue.maxLength) {
          errors.push(this.msg.maxLengthValidation);
        }
        if (!this.$v.inputValue.pattern) {
          if (this.type === 'password') {
            errors.push(this.$options.filters.translate('admin_pattern_error_msg'));
          } else {
            errors.push(this.msg.patternValidationDefault);
          }
        }
        if (!this.$v.inputValue.minValue) {
          errors.push(this.msg.minValidation);
        }
        if (!this.$v.inputValue.maxValue) {
          errors.push(this.msg.maxValidation);
        }
        if (this.isEmailMismatched) {
          errors.push(this.msg.emailMismatched);
        }
      }
      return errors;
    },
    inputHasError() {
      return this.$v.inputValue.$error || !!this.serverErrors.length
        || (this.type === 'date' && this.$v.inputValue.$dirty && !this.isDateValid)
        || ((this.type === 'time' || this.type === 'timeWithSeconds') && this.$v.inputValue.$dirty && !this.isTimeValid);
    },
    isDateValid() {
      let date = this.inputValue;
      if (!this.required && (date === null || date === '')) return true;
      date = moment(date, 'DD.MM.YYYY', true);
      return date.isValid();
    },
    isTimeValid() {
      const time = this.inputValue;
      if (!this.required && (time === null || time === '')) return true;
      const regex = this.type === 'time'
        ? /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
        : /^([0-9]|0[0-9]|1[0-9]|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/;
      if (!regex.test(time)) return false;
      return true;
    },
    isEmailMismatched() {
      // this.value = value of current input
      // this.emailValue = original mail input value - value need to match emailValue
      return this.emailValue && this.emailValue !== this.value;
    },
  },
  validations() {
    const numberValidation = {
      integer: false,
      decimal: false,
      minValue: false,
      maxValue: false,
    };

    if (this.type === 'number') {
      numberValidation.minValue = minValue(this.min);
      numberValidation.maxValue = maxValue(this.max);

      if (this.integers) {
        numberValidation.integer = integer;
      } else {
        numberValidation.decimal = decimal;
      }
    }

    return {
      inputValue: {
        required: this.required ? required : false,
        email: this.type === 'email' ? email : false,
        emailControl: this.isEmailMismatched ? sameAs(this.emailValue) : false,
        ...numberValidation,
        minLength: minLength(this.minLength),
        maxLength: maxLength(this.maxLength),
        pattern: this.pattern ? helpers.regex('pattern', this.pattern) : false,
      },
    };
  },
  watch: {
    value(value) {
      this.inputValue = value;
    },
    errors(value) {
      this.serverErrors = value;
    },
  },
  methods: {
    setTouched() {
      this.$v.inputValue.$touch();
    },
    isValid() {
      this.setTouched();
      return !this.$v.inputValue.$error && !this.serverErrors.length;
    },
    onInput(event) {
      let { value } = event.target;

      if (this.type === 'number') {
        // ? change string to number : set value to null when input is empty
        value = value.length ? +(value) : null;
      }

      this.inputValue = value;
      this.setTouched();
      this.$emit('input', value);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');

      if (this.serverErrors.length) {
        this.serverErrors = [];
      }
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-input {
  text-align: left;

  &.has-error {
    color: $color-danger;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba($color-danger, 0.6);
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba($color-danger, 0.6);
    }
    :-moz-placeholder { /* Firefox 18- */
      color: rgba($color-danger, 0.6);
    }

    input {
      padding: 0 9px;
      background-color: rgba($color-danger, 0.05);
      border: 2px solid $color-danger;
      line-height: 36px;

      &:hover,
      &:focus {
        border-color: $color-danger !important;
        outline: 0;
      }

      &:focus {
        padding: 0 9px;
      }
    }

    // error input with height 32px
    &.input-size-32 {
      .input-wrapper {
        input {
          line-height: 28px;

          &:focus {
            line-height: 28px;
          }
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;

    input {
      border-color: $color-text-secondary;
    }
  }

  &.readonly {
    .has-icon {
      cursor: not-allowed;
    }
  }

  // input with height 32px
  &.input-size-32 {
    .input-wrapper {
      input {
        font-size: 14px;
        line-height: 30px;

        &:focus {
          line-height: 28px;
          border: 2px solid $color-theme;
          outline: 0;
        }
      }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-size: 14px;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      font-size: 14px;
    }
    :-moz-placeholder { /* Firefox 18- */
      font-size: 14px;
    }
  }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $color-back-basic;
  font-size: 16px;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $color-back-basic;
  font-size: 16px;
}
:-moz-placeholder { /* Firefox 18- */
  color: $color-back-basic;
  font-size: 16px;
}

.input-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  color: $color-text-secondary;
  font-size: 14px;
  line-height: 14px;

  .info-icon {
    margin-left: 8px;
    color: $color-text-secondary;

    &:hover {
      color: $color-text-primary;
    }

  }

  .field-required {
    color: $color-danger;
    margin-left: 2px;
  }

}

.input-wrapper {
  position: relative;

  &.has-icon {
    input {
      padding-right: 50px !important;
    }

    input[type=number] {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin-left: 50px;
      }
    }

    input + i {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      user-select: none;
    }

    input + i.fa,
    input + i.fas,
    input + i.far,
    input + i.fal,
    input + i.fad,
    input + i.fab,
    input + i.fat  {
      font-size: 16px;
    }
  }

  input {
    display: block;
    padding: 0 10px;
    width: 100%;
    line-height: 38px;
    background-color: $color-back-primary;
    color: $color-text-primary;
    border: 1px solid $border-color;
    border-radius: 4px;
    font-size: 16px;
    outline: 0;

    &:hover {
      border-color: $color-text-secondary;
    }

    &:focus:not[readonly] {
      padding: 0 9px;
      line-height: 36px;
      border: 2px solid $color-theme;
    }

    &[readonly] {
      border-color: $border-color;
      cursor: not-allowed !important;
    }

    &[type=number] {
      padding-right: 0 !important;

      & + i {
        right: 25px;
      }
    }
  }

  i {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: $color-text-secondary;
    font-style: normal;
  }
}

.input-errors {
  padding-top: 4px;
  font-size: 12px;
  font-weight: $font-weight-normal;
  white-space: pre-line;
}
</style>
