import Vue from 'vue';
import moment from 'moment/moment';
import { ls, helpers } from '@/utility';
import { REQUEST_RETRY_TIMEOUT } from '@/utility/constants';
import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
const updateUnreadMessagesCounter = (store, payload) => {
  store.dispatch('communication/updateUnreadMessagesCounter', payload)
    .then((data) => {
      if (data.timestamp) {
        payload.timestamp = data.timestamp; // store last succeeded timestamp
      }
      const { 'auth/isAuthenticated': isAuthenticated } = store.getters;
      if (isAuthenticated) {
        if (data.error) {
          // use last succeeded timestamp to retry request
          setTimeout(() => updateUnreadMessagesCounter(store, payload), REQUEST_RETRY_TIMEOUT);
        } else {
          updateUnreadMessagesCounter(store, data);
        }
      }
    });
};

export default {
  loadLSConfigurations({ dispatch }) {
    // Load Main menu configuration
    const mainMenu = ls.mainMenu.get();
    dispatch('menu/setMainMenuCollapsed', !!mainMenu.collapsed, { root: true });
  },
  getInitAppData({ commit, getters, state }, routeTo) {
    let path;
    // get proper init app data regarding current route
    switch (routeTo.name) {
      case 'AdultDocumentsUploadForm': {
        const { uuid, token } = routeTo.params;
        path = `/api/v1/applications/adult-document-list/${uuid}/${token}/init`;
        break;
      }
      case 'OfferForm': {
        const { id } = routeTo.params;
        path = `/api/v1/applications/${id}/offer-info/init`;
        break;
      }
      case 'ReferenceForm': {
        const { id } = routeTo.params;
        const { type } = routeTo.query;
        path = `/api/v1/adults/${id}/evaluation/${type}/reference/init`;
        break;
      }
      case 'PasswordResetForm': {
        const { id } = routeTo.params;
        path = `/api/v1/accounts/password/reset/check/${id}/init`;
        break;
      }
      case 'PublicObjectList':
      case 'PublicObjectDetails': {
        path = '/api/v1/objects/iframe-object-list/init';
        break;
      }
      default: path = '/api/v1/init';
    }

    if (routeTo.meta.viewing) {
      const lang = routeTo.query.lang || JSON.parse(localStorage.getItem('mdt-language')) || state.language;
      path = `api/v1/appointments/public/booking/init/${lang}`;
    } else if (routeTo.meta.b2b) {
      path = `/api/v1/b2b/objects/${routeTo.params.projectSlug}/public-init`;
    } else if (routeTo.meta.dms) {
      path = '/api/v1/dms/init';
    }
    return api.get(path).then((response) => {
      const { data } = response;
      commit('setSiteTitle', data.siteTitle);
      commit('setBootstrap', data.bootstrap);
      commit('setPublicPageData', data);
      commit('setLanguage', data.language);

      if (routeTo.meta.changeFavicon && data.favicon) {
        const { baseBackendUrl } = state.bootstrap.settings;
        const faviconPath = `${baseBackendUrl}${data.favicon}`;
        helpers.changeFavicon(faviconPath);
      }
      // get current route and check if permitted after init
      // because init updated availableFeatures
      // and if not permitted redirect to 403 page
      const currentRoute = Vue.router.history.current;
      if (!currentRoute.meta?.unprotected) {
        const { 'auth/isRoutePermitted': isRoutePermitted } = getters;
        if (!isRoutePermitted(currentRoute.name)) {
          Vue.router.push({ name: 'AccessDenied' }).catch(() => {});
        }
      }

      return data;
    }).catch((err) => {
      const messages = err?.response?.data;
      // if backend returned translation messages -> save them to boostrap
      if (messages?.general_back) commit('setBootstrap', { messages });
      else { // if backend didn't returned translation messages -> save default to boostrap
        commit('setBootstrap', {
          messages: {
            admin_500_page_message_1: 'It seems something went wrong.',
            admin_500_page_message_2: 'We now know about this mistake and are working to fix it.',
            general_back: 'Back',
          },
        });
      }
      return Promise.reject(err);
    });
  },
  getBootstrap({ commit, dispatch, getters }) {
    const timestamp = parseInt(moment().utc().format('X'), 10);
    return api.get('/api/v1/bootstrap').then((response) => {
      const { data } = response;
      commit('setBootstrap', data);
      commit('setLanguage', data.language);
      commit('menu/initialize', data);

      /* Create pending request to update messenger counter
      * check is there any new unread messages
      * and
      * update it without refreshing page
      */
      const payload = {
        timestamp,
      };
      updateUnreadMessagesCounter({ getters, dispatch }, payload);
      // get current route and check if permitted after bootstrap
      // because bootstrap updated availableFeatures
      // and if not permitted redirect to 403 page
      const currentRoute = Vue.router.history.current;
      if (!currentRoute.meta?.unprotected) {
        const { 'auth/isRoutePermitted': isRoutePermitted } = getters;
        if (!isRoutePermitted(currentRoute.name)) {
          Vue.router.push({ name: 'AccessDenied' }).catch(() => {});
        }
      }
      return data;
    }).catch(() => {
      // print warning message
      console.error('Bootstrap api call or state commit failed. -> Logout -> Login');
      // Logout user on expired token
      commit('auth/logout');
      // Redirect to Login page
      Vue.router.push({ name: 'Login' });
    });
  },
  saveReorderSettings(state, params) {
    // Used with mutlitple tables in project
    const options = {
      value: params.value,
    };
    return api.post(`/api/v1/settings/${params.key}/list`, options);
  },
  notify(state, notifications) {
    if (!notifications) return;
    notifications.forEach((msg) => {
      Vue.notify({
        type: msg.type,
        title: msg.title,
        text: msg.description,
        duration: msg.duration,
      });
    });
  },
  showLightbox({ commit }, settings) {
    commit('showLightbox', settings);
  },
  hideLightbox({ commit }) {
    commit('hideLightbox');
  },
  resetBootstrap({ commit }) {
    commit('resetBootstrap');
  },
  removeFiles(state, files) {
    return api.post('/api/v1/remove-files', files);
  },
  updateBootstrapMessages({ commit }, messages) {
    commit('updateBootstrapMessages', messages);
  },
  getPrivacyPolicy() {
    return api.get('/api/v1/privacy-policy').then((response) => response.data);
  },
  updateDocumentScrollCounter({ commit }) {
    commit('updateDocumentScrollCounter');
  },
  updateMdtAppResize({ commit }) {
    commit('updateMdtAppResize');
  },
  getMelonCoreVersion() {
    return api.get('/api/v1/version').then((response) => response.data);
  },
  getCountryCodeList({ commit }) {
    return api.get('/api/v1/country-list/').then((response) => {
      commit('setCountryCodeList', response.data);
      return response.data;
    });
  },
};
