<template>
  <div class="mdt-file-single">
    <div
      v-if="label"
      class="input-label">
      <div>
        <span>{{ label }}</span>
        <span
          v-if="!required && !hideOptional"
          class="label-optional">
          ({{ 'admin_marketing_optional' | translate }})
        </span>
        <i
          v-if="required"
          class="fa-asterisk field-required" />
      </div>
      <i
        v-if="tooltip"
        v-tooltip="tooltip"
        class="far fa-info-circle info-icon" />
    </div>
    <MdtFileUpload
      v-if="!value"
      :upload-url="uploadUrl"
      :extensions="extensions"
      :size-limit="sizeLimit"
      :multiple="false"
      :disabled="disabled"
      @uploaded="fileUploaded($event[0])" />
    <div
      v-else
      class="preview-row flex-center-v">
      <MdtPdfPreview
        v-if="previewEnabled"
        :src="value"
        :theme="'highlighted'"
        @deleteFile="deleteFile">
        <div
          v-overflow-tooltip
          v-tooltip="{
            content: fileName,
            trigger: 'manual',
          }"
          class="preview-label ">
          {{ fileName }}
        </div>
      </MdtPdfPreview>
      <div
        v-else
        v-overflow-tooltip
        v-tooltip="{
          content: fileName,
          trigger: 'manual',
        }"
        class="preview-label">
        {{ fileName }}
      </div>
      <i
        class="fas fa-trash icon-hover icon-delete"
        @click="deleteFile" />
    </div>
    <div
      v-if="clientErrors.length || serverErrors.length"
      class="input-errors">
      <span class="client-errors">
        {{ clientErrors.join('\n') }}
        {{ clientErrors.length && serverErrors.length ? '\n' : '' }}
      </span>
      <span class="server-errors">
        {{ serverErrors.join('\n') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MdtFileSingle',
  props: {
    value: {
      type: String,
      required: true,
    },
    uploadUrl: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    previewEnabled: {
      type: Boolean,
      default: true,
    },
    extensions: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => [],
    },
    tooltip: {
      type: String,
      default: '',
    },
    hideOptional: {
      type: Boolean,
      default: false,
    },
    sizeLimit: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      serverErrors: this.errors,
      isDirty: false,
      newFile: false,
    };
  },
  computed: {
    fileName() {
      return this.value.substr(this.value.lastIndexOf('/') + 1);
    },
    clientErrors() {
      const errors = [];
      if (this.isDirty && this.required && !this.value) {
        errors.push(this.$options.filters.translate('general_field_is_required'));
      }
      return errors;
    },
  },
  watch: {
    errors(value) {
      this.serverErrors = value;
    },
  },
  methods: {
    isValid() {
      this.isDirty = true;
      return this.required ? !!this.value : true;
    },
    deleteFile() {
      if (this.newFile) {
        this.$store.dispatch('removeFiles', {
          files: [this.value],
        }).catch(this.showResponseError);
      }
      this.isDirty = true;
      this.$emit('deleteFile');

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');
    },
    fileUploaded(file) {
      this.newFile = true;
      this.$emit('uploaded', file);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-file-single {
  width: 100%;
  display: flex;
  flex-direction: column;

  .input-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    color: $color-text-secondary;
    font-size: 14px;
    line-height: 14px;

    .info-icon {
      margin-left: 8px;
      color: $color-text-secondary;

      &:hover {
        color: $color-text-primary;
      }
    }

    .field-required {
      color: $color-danger;
      margin-left: 2px;
    }
  }

  .preview-row {
    background-color: $color-back-light;
    border-radius: 4px;
  }

  .input-errors {
    padding-top: 4px;
    color: $color-danger;
    font-size: 12px;
    font-weight: $font-weight-normal;
    white-space: pre-line;
  }

  .preview-label {
    word-break: break-all;
    color: $color-text-primary;
    font-size: 14px;
    font-weight: $font-weight-bold;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
}
</style>
