<template>
  <MdtModal
    :title="'general_add_pictures' | translate"
    @cancel="cancelImageProcess"
    @close="cancelImageProcess"
    @save="saveImages">
    <div class="section">
      <div class="header flex-center">
        {{ 'general_selected' | translate }}
        -
        {{ `${images.length}/${maxImagesCount}` }}
      </div>
      <vue-scroll>
        <div class="wrapper">
          <Draggable
            :list="dummyList"
            :handle="'.mdt-image'"
            v-bind="draggableOptions"
            :move="checkMove"
            class="draggable"
            @start="onDragStart"
            @end="onDragEnd">
            <MdtFileUpload
              :upload-url="uploadUrl"
              :extensions="allowedExtensions"
              :disabled="images.length >= maxImagesCount"
              :class="{ disabled: images.length >= maxImagesCount }"
              class="upload-button flex-center"
              @uploaded="onImageUpload" />
            <div
              v-for="(img, i) in images"
              :key="`${i}-${img.image}`"
              class="image-wrapper flex-center">
              <img
                v-show="img.loaded"
                :src="img.imageUrl"
                class="mdt-image"
                @load="img.loaded = true;"
                @error="img.loaded = false;">
              <i
                v-if="!img.loaded"
                class="fas fa-image no-image" />
              <div
                class="icon-delete-wrapper flex-center pointer"
                @click="deleteImage(img)">
                <i class="fas fa-trash icon-delete" />
              </div>
            </div>
          </Draggable>
        </div>
      </vue-scroll>
    </div>
  </MdtModal>
</template>

<script>
import { array } from '@/utility';

export default {
  name: 'ModalObjectsImageUpload',
  props: {
    objectId: {
      type: Number,
      required: true,
    },
  },
  data() {
    const { settings } = this.$store.state.bootstrap;
    const { baseBackendUrl } = this.$store.getters;

    return {
      baseBackendUrl,
      dummyList: [], // draggable needs to bind list for events to work
      images: [],
      deletedImages: [],
      maxImagesCount: settings.maxNumObjectImages,
      allowedExtensions: settings.allowedImageTypes,
      uploadUrl: 'api/v1/upload-images',
      actionsPath: `/api/v1/objects/${this.objectId}/upload-images-setup`,
      draggableOptions: {
        animation: 230,
        setData: (dataTransfer) => {
          dataTransfer.dropEffect = 'none';
          dataTransfer.effectAllowed = 'none';
        },
        forceFallback: true,
      },
      loading: false,
    };
  },
  mounted() {
    this.getUploadImages();
  },
  methods: {
    getUploadImages() {
      this.$store.dispatch('marketing/getUploadImages', this.actionsPath).then((images) => {
        images.forEach((img) => {
          img.imageUrl = img.image;
          img.loaded = false;
        });
        this.images = images;
      }).catch(this.showResponseError);
    },
    onImageUpload(imageList) {
      const list = imageList.map((url) => ({
        id: 0,
        image: url,
        imageUrl: `${this.baseBackendUrl}${url}`,
        loaded: false,
      }));

      // Check images count, remove overcounted images
      if ((this.images.length + list.length) > this.maxImagesCount) {
        const removedImages = list.splice(this.maxImagesCount - (this.images.length + list.length));
        this.deletedImages.push(...removedImages);
      }
      this.images = [...this.images, ...list];
    },
    deleteImage(image) {
      const index = this.images.findIndex((imgObj) => imgObj.image === image.image);
      if (index > -1) {
        const [img] = this.images.splice(index, 1);
        this.deletedImages.push(img);
      }
    },
    cancelImageProcess() {
      // Remove only currently uploaded images
      const newImages = this.images.filter((img) => img.id === 0);
      const settings = {
        path: this.actionsPath,
        data: {
          removedImages: newImages,
        },
      };

      // Dont send request to backend if no changes
      if (!settings.data.removedImages.length) {
        this.$emit('close');
        return;
      }

      // Send request to backend if there are changes
      this.$store.dispatch('marketing/postUploadImages', settings).then(() => {
        this.$emit('close');
      }).catch(this.showResponseError);
    },
    saveImages() {
      if (this.loading) return;

      const updatedImages = this.images.map((img) => ({
        id: img.id,
        image: img.id === 0 ? img.image : undefined,
      }));

      const settings = {
        data: {
          removedImages: this.deletedImages,
          updatedImages,
        },
        path: this.actionsPath,
      };

      this.loading = true;
      this.$store.dispatch('marketing/postUploadImages', settings).then((dataResponse) => {
        const refreshedImages = dataResponse.map((img) => img.image);
        this.$emit('refreshImagesData', refreshedImages);
        this.$emit('close');
      }).catch(this.showResponseError)
        .finally(() => {
          this.loading = false;
        });
    },
    checkMove(event) {
      return !event.related?.classList.contains('upload-button');
    },
    onDragStart() {
      // mdtImageDrag is used for droppable placeholder in MdtFileUpload component
      window.mdtImageDrag = true;
    },
    onDragEnd(event) {
      // mdtImageDrag is used for droppable placeholder in MdtFileUpload component
      window.mdtImageDrag = false;
      let { oldIndex, newIndex } = event;
      // lowerise indexes by 1 to ignore first upload-button item
      array.reorderPositions(this.images, --oldIndex, --newIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  width: 880px;

  .header {
    padding-bottom: 10px;
    font-size: 1.125rem;
    font-weight: $font-weight-bold;
  }
}

.wrapper {
  display: flex;
  flex: 1 1 auto;
  max-height: 70vh;
}

.draggable {
  display: flex;
  flex-wrap: wrap;
}

.upload-button,
.image-wrapper {
  flex: 0 0 200px;
  position: relative;
  width: 200px;
  height: 140px;
  min-height: 140px;
  margin: 10px;
  background-color: $color-back-hover;
  border-radius: 4px;
  overflow: hidden;
}

.upload-button {
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    ::v-deep .input-file {
      cursor: not-allowed;
    }
  }

  ::v-deep {
    .options-info {
      display: none;
    }
    .dropbox,
    .progress {
      min-height: 140px;
    }
  }
}

.image-wrapper {
  display: flex;
  flex-wrap: wrap;
  cursor: grab;

  &.sortable-chosen {
    border: 4px dashed $color-white;
    border-radius: 4px;
    box-shadow: 0 3px 14px #00000040;

    img {
      cursor: grabbing !important;
    }
  }

  &.sortable-drag {
    display: none;
  }

  &:hover {
    img,
    .no-image {
      transform: scale(1.1);
    }

    .icon-position-wrapper {
      display: block;
    }
  }

  img,
  .no-image,
  .icon-delete-wrapper,
  .icon-position-wrapper > i {
    transition: transform 0.4s ease,
                background-color 0.4s ease;
  }

  img {
    height: 100%;
  }

  .no-image {
    color: $color-text-secondary;
    font-size: 2.5rem;
  }

  .icon-delete-wrapper {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: rgba($color-back-primary-rgb, 0.5);
    border-radius: 2px;

    &:hover {
      background-color: rgba($color-back-primary-rgb, 0.8);
    }
  }
}
</style>
