import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
export default {
  login({ commit, rootGetters }, loginData) {
    return api.post('/api/v1/login', loginData).then((response) => {
      // get available features and update it to response.data
      const { availableFeatures } = rootGetters;
      response.data.features = availableFeatures;
      commit('login', {
        ...response.data,
        userType: 'melon',
      });
      return response.data;
    });
  },
  msalLogin({ commit, rootGetters }, loginData) {
    return api.post('api/v2/azure/sso', loginData).then((response) => {
      // get available features and update it to response.data
      const { availableFeatures } = rootGetters;
      response.data.features = availableFeatures;
      commit('login', {
        ...response.data,
        userType: 'melon',
      });
      return response.data;
    });
  },
  twoFaLogin({ commit, rootGetters }, loginData) {
    return api.post('/api/v1/2fa-authentication', loginData).then((response) => {
      // get available features and update it to response.data
      const { availableFeatures } = rootGetters;
      response.data.features = availableFeatures;
      commit('login', {
        ...response.data,
        userType: 'melon',
      });
      return response.data;
    });
  },
  // eslint-disable-next-line no-unused-vars
  logout({ commit, dispatch, state }) {
    const { token } = state.user;
    const payload = {
      token,
    };
    return api.post('/api/v1/logout', payload).then((response) => {
      commit('logout');
      dispatch('resetBootstrap', null, { root: true });
      return response.data;
    });
  },
  resetPassword(state, params) {
    return api.post('/api/v1/accounts/password/reset/request', params)
      .then((response) => response.data);
  },
  resetPasswordCheck(state, settings) {
    return api.get(`/api/v1/accounts/password/reset/check/${settings.id}`)
      .then((response) => response.data);
  },
  resetPasswordConfirm(state, settings) {
    return api.post(`/api/v1/accounts/password/reset/confirm/${settings.id}`, settings.params)
      .then((response) => response.data);
  },
  updateUser({ commit }, user) {
    commit('updateUser', user);
  },
};
