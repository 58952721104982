<template>
  <div
    class="mdt-checkbox"
    :class="[{ checked }, { disabled }, { 'has-error': clientErrors.length }]">
    <input
      type="checkbox"
      class="labeled"
      :checked="checked"
      :disabled="disabled"
      @change="onChange">
    <div class="state">
      <slot name="icon">
        <i class="fas fa-check icon" />
      </slot>
      <label>
        <slot />
      </label>
    </div>
    <div
      v-if="clientErrors.length"
      class="input-errors">
      <span class="client-errors">
        {{ clientErrors.join('\n') }}
      </span>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';

export default {
  name: 'MdtCheckbox',
  mixins: [validationMixin],
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    clientErrors() {
      const errors = [];
      if (this.$v.$error) {
        errors.push(this.$options.filters.translate('general_field_is_required'));
      }
      return errors;
    },
  },
  validations() {
    return {
      checked: {
        required: () => this.checked,
      },
    };
  },
  methods: {
    setTouched() {
      this.$v.checked.$touch();
    },
    isValid() {
      this.setTouched();
      return !this.$v.checked.$error;
    },
    onChange(e) {
      const { checked } = e.target;
      this.$emit('change', checked);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-checkbox {
  position: relative;
  display: inline-block;
  color: $color-text-secondary;
  font-size: 16px;
  line-height: calc(1em + 2px);

  &:hover:not(.checked) {
    label:before,
    label::after {
      border-color: $color-text-secondary;
    }
  }

  &.checked {
    color: $color-text-primary;
  }

  &.disabled {
    opacity: 0.25;

    & > input {
      cursor: not-allowed;
    }
  }

  & > input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .state label:before {
      border-color: $color-theme;
    }

    &:checked ~ .state label:after {
      background-color: $color-theme;
      border-color: $color-theme;
    }

    &:checked ~ .state .icon {
      opacity: 1;
    }
  }

  .state {
    label {
      position: initial;
      display: inline-block;
      min-width: calc(16px + 2px);
      text-indent: 24px;
      font-size: 16px;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: calc(16px + 2px);
        height: calc(16px + 2px);
        background-color: transparent;
        border: 2px solid transparent;
        border-radius: 20%;
        z-index: 0;
      }

      &:before {
        border-color: $border-color;
      }
    }

    .icon {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(16px + 2px);
      height: calc(16px + 2px);
      line-height: calc(1em + 2px);
      transform: scale(0.8);
      text-align: center;
      color: $color-white;
      font-size: 16px;
      z-index: 1;
    }
  }
}

.input-errors {
  padding-top:4px;
  font-size: 12px;
  font-weight: $font-weight-normal;
  color: $color-danger;
  white-space: pre-line;
}

.has-error {
  color: $color-danger;
}
</style>
