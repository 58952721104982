<template>
  <div
    v-if="viewReady"
    class="template-default">
    <MdtLightbox />
    <MdtNotify />
    <div
      class="main-wrapper"
      :class="{ 'navigation-collapsed': menuCollapsed }">
      <div
        class="main-navigation-left"
        :class="{ collapsed: menuCollapsed, hovered: collapsedHovered }">
        <NavigationLeft
          :menu-collapsed="menuCollapsed"
          :collapsed-hovered="collapsedHovered" />
      </div>
      <div
        class="main-center-container"
        :class="{ 'main-navigation-collapsed': menuCollapsed }">
        <div
          class="main-navigation-top"
          :class="{ 'main-navigation-collapsed': menuCollapsed }">
          <NavigationTop />
        </div>
        <div class="main-container">
          <RouterView
            class="main-content-router"
            @openSidepanel="openSidepanel" />
        </div>
      </div>
    </div>
    <transition name="slide-left">
      <MdtSidepanel v-if="!!sidepanel.key" />
    </transition>
  </div>
  <div
    v-else
    class="view-not-ready flex-center">
    <MdtLoader />
  </div>
</template>

<script>
import apiPointMaps from '@/store/model/api-point-maps/apiPointMaps';
import NavigationLeft from '@/components/navigation-left/NavigationLeft.vue';
import NavigationTop from '@/components/navigation-top/NavigationTop.vue';
import MdtSidepanel from '@/components/shared/sidepanel/MdtSidepanel.vue';
import MdtLightbox from '@/components/shared/MdtLightbox.vue';
import { MELON_LANGUAGE_DE } from '@/utility/constants';

export default {
  name: 'DashboardView',
  components: {
    NavigationLeft,
    NavigationTop,
    MdtSidepanel,
    MdtLightbox,
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.dispatch('menu/updatePrevRoutes', from);
    this.$store.dispatch('sidepanel/close');
    this.$nextTick(next);
  },
  data() {
    return {
      viewReady: false,
    };
  },
  computed: {
    sidepanel() {
      return this.$store.state.sidepanel;
    },
    menuCollapsed() {
      return this.$store.getters['menu/mainMenuCollapsed'];
    },
    collapsedHovered() {
      return this.$store.getters['menu/mainMenuCollapsedHovered'];
    },
    language() {
      return this.$store.getters.language;
    },
  },
  watch: {
    $route(to, from) {
      // don't reload table data if we didn't changed route name
      // changing table state filters will change router query params and refresh table data only
      // and without this check it will reload whole table data and reset all filters etc.
      if (to.name !== from.name) this.getTableData(to);
    },
  },
  created() {
    if (!this.$store.state.bootstrap.bootstrapSet) {
      // GET UTC timestamp
      this.$store.dispatch('getBootstrap')
        .then(() => {
          this.viewReady = true;
          this.getTableData(this.$route);
          if (this.language === MELON_LANGUAGE_DE) {
            this.$freshdeskHelpWidget.init();
          }
        });
    } else {
      this.viewReady = true;
      this.getTableData(this.$route);
    }
  },
  methods: {
    getTableData(route) {
      // Get table data depending on route
      let tableRouteMapping = {};
      Object.keys(apiPointMaps.getTableData).forEach((key) => {
        tableRouteMapping = { ...tableRouteMapping, ...apiPointMaps.getTableData[key] };
      });
      const path = tableRouteMapping[route.name];

      if (path) {
        this.$store.dispatch('table/getTableData', path);
      }
    },

    openSidepanel(settings) {
      let params = {};
      switch (settings.key) {
        case 'ApplicationAdult': {
          params = { id: settings.additional.adultId };
          break;
        }
        case 'ApartmentEdit':
        case 'ApartmentOffer': {
          params = { id: settings.additional.apartmentId };
          break;
        }
        case 'ApplicationEdit':
        case 'ApplicationEvaluation':
        case 'ApplicationOffer': {
          params = { id: settings.additional.applicationId };
          break;
        }
        case 'ApplicationChild': {
          params = { id: settings.additional.childId };
          break;
        }
        case 'ApplicationOfferDeclined': {
          params = {
            apartmentId: settings.additional.apartmentId,
            applicationId: settings.additional.applicationId,
          };
          break;
        }
        case 'BudgetCostPlanEdit':
        case 'BudgetCostCategoryEdit':
        case 'ApplicationTotalPoints':
        case 'BuildingEdit': {
          params = { id: settings.row._id };
          break;
        }
        case 'Messenger':
          params = settings.additional;
          break;
        case 'InvestorEdit':
        case 'InterestedPersonB2BEdit':
          params = { id: settings.additional.value };
          break;
        case 'InvestorOffer':
          params = { id: settings.row._apply_id }; // eslint-disable-line no-underscore-dangle
          break;
        default: {
          params = { id: settings.additional.id };
        }
      }

      // Open sidepanel
      this.$store.dispatch('sidepanel/open', {
        key: settings.key,
        params,
        tableRowId: settings?.row?._id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.view-not-ready {
  height: 100vh;
}

.main-container {
  .main-content-router {
    width: 100%;
    transition-duration: .4s;
    transition-property: width;
    transition-timing-function: ease-out;
  }
}
</style>
