<template>
  <div>
    <MdtNotify />
    <div class="application-form">
      <div class="header-helper" :style="{ height: headerHelperHeight }" />
      <UnprotectedViewHeader />
      <RouterView />
      <UnprotectedViewFooter
        :left="formsSettings.product_form_left_footer"
        :right="formsSettings.product_form_right_footer"
        :datenschutz="formsSettings.product_form_data_protection" />
    </div>
  </div>
</template>

<script>
import UnprotectedViewHeader from '@/components/unprotected-view/UnprotectedViewHeader.vue';
import UnprotectedViewFooter from '@/components/unprotected-view/UnprotectedViewFooter.vue';

export default {
  name: 'ApplicationForm',
  components: {
    UnprotectedViewHeader,
    UnprotectedViewFooter,
  },
  computed: {
    formsSettings() {
      return this.$store.getters['applicationForm/getFormsSettings'];
    },
    headerHelperHeight() {
      return this.$route.name === 'CreateAdult'
        ? '125px'
        : '225px';
    },
  },
};
</script>

<style lang="scss" scoped>
.application-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: $color-back-light;

  .header-helper {
    position: fixed;
    width: 100%;
    height: 225px;
    background: $color-back-light;
    z-index: 10;
  }

  ::v-deep .unprotected-view-header {
    position: fixed;
    background: $color-back-light;
    z-index: 10;
  }
  ::v-deep .footer-info-left {
    p:first-child {
      font-size: 14px !important;
    }
  }
}
</style>
