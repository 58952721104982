<template>
  <div
    v-click-outside="closeDropdown"
    class="mdt-select"
    :class="[
      { 'has-error': $v.selectedValue.$error || serverErrors.length, disabled, readonly },
      `select-${size}`]"
    :style="{ width: width || null, maxWidth: maxWidth || null, minWidth: minWidth || null }">
    <div
      v-if="label"
      class="select-label">
      <span>
        {{ label }}
        <span v-if="!required && !hideOptional">
          ({{ 'admin_marketing_optional' | translate }})
        </span>
        <i
          v-if="required"
          class="fa-asterisk field-required" />
      </span>
      <i
        v-if="tooltip"
        v-tooltip="tooltip"
        class="far fa-info-circle info-icon" />
    </div>
    <div
      class="select-wrapper"
      :class="{ focused: isDropdownOpen }">
      <div
        v-if="!isDropdownOpen"
        class="search display flex-center-v"
        @click.stop="onToggleDropdown">
        <MdtInput
          :value="selectedDisplayName"
          :placeholder="searchboxPlaceholder"
          :size="size"
          :disabled="disabled"
          :readonly="readonly" />
        <i class="fas fa-caret-down icon-dropdown pointer" />
      </div>
      <div
        v-else
        class="search flex-center-v"
        @keyup.up="goToPreviousItem"
        @keyup.down="goToNextItem"
        @keyup.enter="selectItem"
        @keydown.esc="clearSearch">
        <MdtInput
          ref="search"
          v-model="search"
          :placeholder="searchboxPlaceholder"
          :size="size"
          :disabled="disabled"
          :readonly="readonly" />
        <span
          v-if="search"
          class="search-clear"
          @click.stop="clearSearch">
          <i class="fas fa-times-circle" />
        </span>
        <i
          class="fas fa-caret-up icon-dropdown pointer"
          @click.stop="closeDropdown" />
      </div>
      <div
        v-if="isDropdownOpen"
        class="select-dropdown"
        :class="[position, { 'right-aligned': rightAligned, relative }]">
        <vue-scroll
          ref="scroll"
          :ops="vueScrollOptions">
          <ul
            class="select-dropdown-items-wrapper"
            :style="{ maxWidth: maxWidth || null, maxHeight: maxHeight || null }">
            <li
              v-for="(option, i) in selectOptions"
              :key="i"
              v-overflow-tooltip
              class="dropdown-item"
              :class="{
                selected: option.value === value,
                'mdt-select-no-items': option.value === 'mdt-select-no-items',
                'item-disabled': option.disabled,
              }"
              :data-value="option.value"
              @click.stop="option.value !== 'mdt-select-no-items' && selectOption(option)">
              <div class="text-cut">
                {{ option.displayName }}
              </div>
            </li>
          </ul>
        </vue-scroll>
        <slot />
      </div>
    </div>
    <div
      v-if="clientErrors.length || serverErrors.length"
      class="input-errors">
      <span class="client-errors">
        {{ clientErrors.join('\n') }}
        {{ clientErrors.length && serverErrors.length ? '\n' : '' }}
      </span>
      <span class="server-errors">
        {{ serverErrors.join('\n') }}
      </span>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mixinToggleDropdown } from '@/mixins';
import { SELECTED_ITEM_IS_APPLICATION } from '@/utility/constants';

export default {
  name: 'MdtSelect',
  mixins: [mixinToggleDropdown, validationMixin],
  props: {
    value: {
      required: true,
      validator: (value) => {
        const isString = typeof value === 'string';
        const isBoolean = typeof value === 'boolean';
        const isNumber = typeof value === 'number';
        const isObject = typeof value === 'object';
        const isNull = value === null;
        return isString || isBoolean || isNumber || isObject || isNull;
      },
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    optionPlaceholder: {
      type: String,
      default: '-',
    },
    position: {
      type: String,
      default: 'bottom',
      validator: (value) => ['bottom', 'top'].includes(value),
    },
    required: {
      type: Boolean,
      default: false,
    },
    rightAligned: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    hideEmptyItem: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: '',
    },
    relative: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '',
    },
    minWidth: {
      type: String,
      default: '200px',
    },
    maxHeight: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'size-40',
      validator: (value) => {
        const match = ['size-40', 'size-32'];
        return match.includes(value);
      },
    },
    isZeroAsValueAllowed: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
    hideOptional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      selectedValue: this.value,
      serverErrors: this.errors || [],
      vueScrollOptions: {
        scrollPanel: {
          scrollingX: false,
        },
        bar: {
          keepShow: true,
        },
      },
      msg: {
        noObjectsAvailable: this.$options.filters.translate('admin_no_objects_available'),
      },
    };
  },
  validations() {
    // Custom validator if value is selected
    const isValueSelected = (value) => {
      const index = this.selectOptions.findIndex((option) => option.value === value);
      return index > -1;
    };

    return {
      selectedValue: {
        required: this.required ? required : true,
        isValueSelected: this.required ? isValueSelected : true,
      },
    };
  },
  computed: {
    selectOptions() {
      let { options } = this;
      if (this.search) {
        options = options.filter(
          (obj) => obj.displayName.toLowerCase().indexOf(this.search.toLowerCase()) > -1,
        );
      }
      if (!options.length) {
        options = [{ displayName: this.msg.noObjectsAvailable, value: 'mdt-select-no-items' }, ...options];
      }
      if (!this.required && this.hideEmptyItem !== true) {
        options = [{ displayName: '-', value: '' }, ...options];
      }
      return options;
    },
    clientErrors() {
      const errors = [];
      const isDirty = this.$v.selectedValue.$dirty;
      if (!this.$v.selectedValue.required && isDirty) {
        errors.push(this.$options.filters.translate('general_field_is_required'));
      }
      return errors;
    },
    selectedDisplayName() {
      const selected = this.selectOptions
        .find((option) => option.value === this.selectedValue);

      if (this.isZeroAsValueAllowed && selected?.value === SELECTED_ITEM_IS_APPLICATION) {
        return selected.displayName;
      }
      return selected?.value ? selected.displayName : null;
    },
    searchboxPlaceholder() {
      return this.searchPlaceholder || `${this.$options.filters.translate('general_search')}...`;
    },
  },
  watch: {
    value(value) {
      this.selectedValue = value;
    },
    errors(value) {
      this.serverErrors = value;
    },
    isDropdownOpen(value) {
      if (value) {
        this.search = '';
        this.clearHoveredItems();
        this.$nextTick(() => {
          this.focusInput();
          this.goToSelectedItem();
        });
      }
    },
  },
  mounted() {
    this.$emit('input', this.selectedValue, 'init');
  },
  methods: {
    setTouched() {
      this.$v.selectedValue.$touch();
    },
    isValid() {
      this.setTouched();
      return !this.$v.selectedValue.$error && !this.serverErrors.length;
    },
    selectOption(option) {
      const { value, disabled } = option;
      if (disabled) return;
      this.setTouched();
      this.selectedValue = value;
      this.$emit('input', value);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');

      this.closeDropdown();

      // Reset server errors
      this.serverErrors = [];
    },
    clearSearch() {
      if (!this.search) this.closeDropdown();
      else {
        this.search = '';
        this.focusInput();
        this.$nextTick(() => {
          this.goToSelectedItem();
        });
      }
      this.clearHoveredItems();
    },
    clearHoveredItems() {
      const scroll = this.$refs.scroll?.$el;
      if (scroll) {
        const hoveredItems = scroll.querySelectorAll('li.hovered');
        hoveredItems.forEach((item) => {
          item.classList.remove('hovered');
        });
      }
    },
    focusInput() {
      const searchRef = this.$refs.search;
      if (searchRef && searchRef.focus) searchRef.focus();
    },
    goToSelectedItem(item) {
      const scroll = this.$refs.scroll?.$el;
      if (scroll) {
        // scroll list to the selected item if exists
        const selectedItem = item || scroll.querySelector('li.selected');
        if (selectedItem) {
          scroll.firstChild.scroll({ top: selectedItem.offsetTop, behavior: 'smooth' });
        }
        // scroll the view if neccessary to show whole component
        scroll.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    },
    goToPreviousItem() {
      const scroll = this.$refs.scroll?.$el;
      if (scroll) {
        const items = scroll.querySelectorAll('li');
        const selectedItem = scroll.querySelector('li.selected');
        const hoveredItem = scroll.querySelector('li.hovered');
        let previousItem;
        if (!selectedItem && !hoveredItem) {
          [previousItem] = items;
        } else if (items.length === 1) {
          [previousItem] = items;
        } else {
          previousItem = hoveredItem
            ? hoveredItem.previousElementSibling
            : selectedItem.previousElementSibling;
        }
        if (!previousItem) return;
        items.forEach((item) => {
          item.classList.remove('hovered');
        });

        // if previous item is 'mdt-select-no-items' -> don't select it
        if (previousItem?.dataset?.value === 'mdt-select-no-items') return;

        previousItem.classList.add('hovered');
        this.goToSelectedItem(previousItem);
      }
    },
    goToNextItem() {
      const scroll = this.$refs.scroll?.$el;
      if (scroll) {
        const items = scroll.querySelectorAll('li');
        const selectedItem = scroll.querySelector('li.selected');
        const hoveredItem = scroll.querySelector('li.hovered');
        let nextItem;
        if (!selectedItem && !hoveredItem) {
          [nextItem] = items;
        } else if (items.length === 1) {
          [nextItem] = items;
        } else {
          nextItem = hoveredItem
            ? hoveredItem.nextElementSibling
            : selectedItem.nextElementSibling;
        }
        if (!nextItem) return;
        items.forEach((item) => {
          item.classList.remove('hovered');
        });

        // if next item is 'mdt-select-no-items' -> don't select it
        if (nextItem?.dataset?.value === 'mdt-select-no-items') return;

        nextItem.classList.add('hovered');
        this.goToSelectedItem(nextItem);
      }
    },
    selectItem() {
      const scroll = this.$refs.scroll?.$el;
      if (scroll) {
        const hoveredItem = scroll.querySelector('li.hovered');
        if (!hoveredItem) return;
        const option = this.selectOptions
          .find((obj) => obj.value.toString() === hoveredItem.dataset.value);
        if (option) this.selectOption(option);
      }
    },
    onToggleDropdown() {
      if (this.disabled || this.readonly) return;
      this.toggleDropdown();
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-select {
  position: relative;
  width: 100%;

  &.has-error {
    color: $color-danger;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba($color-danger, 0.6);
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba($color-danger, 0.6);
    }
    :-moz-placeholder { /* Firefox 18- */
      color: rgba($color-danger, 0.6);
    }

    .search {
      background-color: rgba($color-danger, 0.05);
      border: 2px solid $color-danger !important;
      line-height: 36px;

      &:hover,
      &:focus {
        border-color: $color-danger;
      }
    }
  }

  // select with height 32px
  &.select-size-32 {
    .search {
      min-height: 32px;
      line-height: 30px;
    }

    .select-wrapper {
      &.focused {
        .search {
          min-height: 30px;
          max-height: 32px;
          line-height: 28px;
        }
      }
    }

    .search {
      .search-clear {
        right: 32px;
        font-size: 14px;
      }
    }

    .icon-dropdown {
      font-size: 14px;
    }

    .select-dropdown {
      .dropdown-item {
        font-size: 14px;
      }
    }
  }
  .item-disabled {
    cursor: not-allowed !important;
    opacity: 0.3;
  }

  &.disabled {
    cursor: default;
    .select-label,
    .client-errors,
    .icon-dropdown {
      opacity: 0.3;
    }

    * {
      pointer-events: none;
    }
  }

  .readonly {
    cursor: not-allowed;

    .select-label,
    .input-errors {
      cursor: text;
    }

    .select-wrapper * {
      pointer-events: none;
    }
  }

  .select-wrapper {
    position: relative;
    min-width: 200px;

    &.focused {
      .search {
        min-height: 38px;
        max-height: 40px;
        line-height: 36px;
        border: 2px solid $color-theme;
        outline: 0;
      }

      .search {
        ::v-deep {
          input {
            padding: 0 9px;
          }
        }
      }
    }
  }

  .select-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    color: $color-text-secondary;
    font-size: 14px;
    line-height: 14px;

    .field-required {
      color: $color-danger;
      margin-left: 2px;
    }
  }

  .search {
    min-height: 40px;
    line-height: 38px;
    background-color: $color-back-primary;
    border: 1px solid $border-color;
    border-radius: 4px;
    cursor: text;
    overflow: hidden;

    &.display {
      cursor: pointer;

      ::v-deep {
        input {
          background-color: transparent;
          cursor: pointer;
        }
      }
    }

    &:hover {
      border-color: $color-text-secondary;
    }

    ::v-deep .mdt-input {
      // input with height 40px
      &.input-size-40 {
        .input-wrapper {
          height: 38px;
        }
      }

      // input with height 32px
      &.input-size-32 {
        .input-wrapper {
          height: 30px;
        }
      }

      input {
        height: 100%;

        &:focus {
          border: none;
        }
      }
    }
  }

  .icon-dropdown {
    position: absolute;
    top: calc(50% - 8px);
    right: 12px;
    color: $color-text-secondary;
    font-size: 16px;
  }

  .search {
    padding: 0;
    border: 1px solid $border-color;
    border-radius: 4px;

    .search-clear {
      position: absolute;
      right: 34px;
      padding: 0;
      color: $color-text-secondary;
      font-size: 16px;
      cursor: pointer;
    }

    .mdt-input {
      width: 100%;
      padding-right: 32px;
      outline: none;
    }

    ::v-deep .input-wrapper input {
      padding: 0 10px;
      border: none;
      box-shadow: none;
    }
  }

  .select-dropdown {
    position: absolute;
    left: 0;
    top: calc(100% + 4px);
    width: 100%;
    min-width: 200px;
    background-color: $color-back-primary;
    box-shadow: 0px 2px 20px 0px #0000002a;
    border-radius: 4px;
    overflow: hidden;
    z-index: 111;

    &.right-aligned {
      left: auto;
      right: 0;
    }

    &.top {
      top: auto;
      bottom: calc(100% + 4px);
    }

    &.relative {
      position: relative;
      top: auto;
      margin-top: 4px;

      &.top {
        margin-bottom: 4px;
      }
    }

    &.relative {
      position: relative;
    }

    &.relative {
      position: relative;
    }

    .select-dropdown-items-wrapper {
      max-height: 200px;
      list-style: none;
    }

    .dropdown-item {
      height: 40px;
      line-height: 40px;
      padding: 0 12px;
      color: $color-text-secondary;
      font-size: 16px;
      white-space: nowrap;
      cursor: pointer;

      &.mdt-select-no-items {
        user-select: none;
        cursor: default;

        &:hover,
        &.hovered {
          background-color: transparent !important;
        }
      }

      &.selected {
        background-color: $color-back-basic;
        color: $color-text-primary;
      }

      &:hover:not(.selected),
      &.hovered:not(.selected) {
        color: $color-text-primary;
        background-color: $color-back-light;
      }

      .text {
        height: 100%;
      }
    }
  }
}

.input-errors {
  padding-top: 4px;
  font-size: 12px;
  font-weight: $font-weight-normal;
  white-space: pre-line;
}
</style>
